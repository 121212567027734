import * as React from "react";

function NcLogo(props) {
  return (
    <svg viewBox="0 0 225 225" {...props}>
      <circle cx={113} cy={113} r={110} fill="#242424" />
      <linearGradient
        id="prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={107.271}
        y1={12.937}
        x2={107.271}
        y2={208.281}
        gradientTransform="matrix(1 0 0 -1 0 226)"
      >
        <stop offset={0} stopColor="#c07fd0" />
        <stop offset={1} stopColor="#eba4d8" />
      </linearGradient>
      <path
        d="M202 137c-4.1 17.3-12.7 33.2-25 46-14.8 15.5-37.5 29-64 30-44.4 1.8-89.3-31.5-98.7-79C4.7 84.7 35.2 29.5 88.7 19.3 138.9 9.8 189.7 43.7 200 96"
        fill="none"
        stroke="url(#prefix__a)"
      />
      <path
        d="M218.5 114.2c.7.7.7 1.9 0 2.6 0 0 0 0 0 0l-15.7 15.7c-.7.7-1.9.7-2.6 0 0 0 0 0 0 0l-15.7-15.7c-.7-.7-.7-1.9 0-2.6 0 0 0 0 0 0l15.7-15.7c.7-.7 1.9-.7 2.6 0 0 0 0 0 0 0l15.7 15.7z"
        fill="none"
        stroke="#fff"
      />
      <linearGradient
        id="prefix__b"
        gradientUnits="userSpaceOnUse"
        x1={201.495}
        y1={93}
        x2={201.495}
        y2={128}
        gradientTransform="matrix(1 0 0 -1 0 226)"
      >
        <stop offset={0} stopColor="#c07fd0" />
        <stop offset={1} stopColor="#eba4d8" />
      </linearGradient>
      <path
        d="M218.5 114.2c.7.7.7 1.9 0 2.6 0 0 0 0 0 0l-15.7 15.7c-.7.7-1.9.7-2.6 0 0 0 0 0 0 0l-15.7-15.7c-.7-.7-.7-1.9 0-2.6 0 0 0 0 0 0l15.7-15.7c.7-.7 1.9-.7 2.6 0 0 0 0 0 0 0l15.7 15.7z"
        fill="none"
        stroke="url(#prefix__b)"
      />
      <linearGradient
        id="prefix__c"
        gradientUnits="userSpaceOnUse"
        x1={210.636}
        y1={130}
        x2={210.636}
        y2={131}
        gradientTransform="matrix(1 0 0 -1 0 226)"
      >
        <stop offset={0} stopColor="#c07fd0" />
        <stop offset={1} stopColor="#eba4d8" />
      </linearGradient>
      <linearGradient
        id="prefix__d"
        gradientUnits="userSpaceOnUse"
        x1={210.636}
        y1={130}
        x2={210.636}
        y2={131}
        gradientTransform="matrix(1 0 0 -1 0 226)"
      >
        <stop offset={0} stopColor="#c07fd0" />
        <stop offset={1} stopColor="#eba4d8" />
      </linearGradient>
      <path
        d="M200.1 96v-1h21v1h-21z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__c)"
        stroke="url(#prefix__d)"
        strokeWidth={0.75}
      />
      <linearGradient
        id="prefix__e"
        gradientUnits="userSpaceOnUse"
        x1={210.998}
        y1={89}
        x2={210.998}
        y2={90}
        gradientTransform="matrix(1 0 0 -1 0 226)"
      >
        <stop offset={0} stopColor="#c07fd0" />
        <stop offset={1} stopColor="#eba4d8" />
      </linearGradient>
      <linearGradient
        id="prefix__f"
        gradientUnits="userSpaceOnUse"
        x1={210.998}
        y1={89}
        x2={210.998}
        y2={90}
        gradientTransform="matrix(1 0 0 -1 0 226)"
      >
        <stop offset={0} stopColor="#c07fd0" />
        <stop offset={1} stopColor="#eba4d8" />
      </linearGradient>
      <path
        d="M202 137v-1h18v1h-18z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__e)"
        stroke="url(#prefix__f)"
        strokeWidth={0.75}
      />
      <linearGradient
        id="prefix__g"
        gradientUnits="userSpaceOnUse"
        x1={103.456}
        y1={43.993}
        x2={103.456}
        y2={181.286}
        gradientTransform="matrix(1 0 0 -1 0 226)"
      >
        <stop offset={0} stopColor="#c07fd0" />
        <stop offset={1} stopColor="#eba4d8" />
      </linearGradient>
      <path
        d="M157.1 90.2c2.7-12.5.5-23.3-6.5-32.2-7-8.8-16.8-13.3-29.4-13.3-12.5 0-24.1 4.4-34.9 13.3-10.8 8.9-17.5 19.6-20.2 32.2L49 171.4c-.7 2.6-.1 5.4 1.6 7.5 1.6 2.1 4.1 3.3 6.8 3.2h.3c3-.1 5.9-1.2 8.2-3.2 2.4-1.8 4.1-4.5 4.7-7.5l17.2-81.2c1.5-6.7 5.2-12.7 10.5-17 5.7-4.7 11.8-7 18.5-7 6.7 0 11.9 2.3 15.6 7 3.6 4.7 4.7 10.3 3.3 17l-17.2 81.2c-.7 2.6-.1 5.4 1.6 7.5 1.6 2.1 4.1 3.3 6.8 3.2h.3c6.2-.3 11.4-4.6 12.9-10.6l17-81.3z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#prefix__g)"
      />
      <path
        d="M56 182c4.9-22.1 9.9-44 15-66 1-4.3 2-8.7 3-13l4-18c1.5-5.5 4.3-10.6 8-15 4.8-5.6 11.9-10.4 22-13 1.6-.4 3.3-.8 5-1 6.4-.8 17.7-.5 26 7 2.8 2.5 5 5.6 6.5 9.1 3.7 8.6 1.7 16.7.5 21.9-1.4 6.1-1.4 11-3 17-4.1 15.7-10.9 47.5-16 71"
        fill="none"
        stroke="#000"
      />
    </svg>
  );
}

const MemoNcLogo = React.memo(NcLogo);
export default MemoNcLogo;
