import * as React from "react";

function AbstractProductUSA(props) {
  return (
    <svg
      viewBox="0 0 300 300"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M283.482 89.99c0-32.16-26.11-58.27-58.27-58.27H108.674c-32.16 0-58.27 26.11-58.27 58.27v120.018c0 32.16 26.11 58.27 58.27 58.27h116.538c32.16 0 58.27-26.11 58.27-58.27V89.991z"
        fill="#fcfcfc"
      />
      <path
        d="M273.896 83.851c0-19.08-15.49-34.57-34.57-34.57H96.26c-19.08 0-34.57 15.49-34.57 34.57v69.14c0 19.08 15.49 34.571 34.57 34.571h143.066c19.08 0 34.57-15.49 34.57-34.57V83.85z"
        fill="#6f4141"
      />
      <text
        x={101.707}
        y={229.28}
        fontFamily="'Raleway-Regular','Raleway'"
        fontSize={12}
        transform="translate(18.448 -16.764)"
      >
        {"Abstract Pr"}
        <tspan x={162.919} y={229.28}>
          {"o"}
        </tspan>
        {"duct"}
      </text>
      <text
        x={101.707}
        y={229.28}
        fontFamily="'Raleway-Regular','Raleway'"
        fontSize={12}
        fill="#a83a3a"
        transform="translate(-15.796 -112.494) scale(1.5254)"
      >
        {"$10.99"}
      </text>
      <g fillRule="nonzero">
        <path
          d="M115.826 147.302c3.924 4.689 9.258 5.702 11.848 3.537 2.59-2.165 4.594-7.62 10.918-5.521-2.922-7.752 5.79-17.152 14.307-13.122 2.274-10.51 23.686-11.072 25.177 1.7 19.097-5.391 22.863 34.094 0 31.092-.338 10.36-19.082 14.24-21.275 4.406-3.784 8.132-18.933 9.237-20.519-1.753-10.176 4.455-19.198-7.873-20.456-20.34v.001z"
          fill="#fff"
        />
        <path
          d="M165.843 125.819c-2.662-.065-5.354.556-7.426 1.728s-3.503 2.822-3.969 4.976c-4.88 17.505 16.485 13.998 11.508 3.058l1.86-1.014c6.107 14.25-19.478 17.681-15.476-1.019-7.344-3.475-14.684 4.786-12.25 11.243.2.528.07 1.167-.32 1.574a1.536 1.536 0 01-1.56.383c-2.749-.913-4.052-.28-5.413.873-1.36 1.152-2.44 3.011-4.064 4.368-1.772 1.482-4.29 1.76-6.78 1.102-1.271-.336-2.556-.936-3.798-1.766 1.1 4.443 3.149 8.602 5.754 11.49 3.412 3.78 7.378 5.414 11.851 3.456-1.38-9.098 12.578-13.468 13.807-2.432l-1.735.118c-1.226-8.516-12.124-5.205-9.982 3.473.583 2.362 1.37 3.988 2.773 5.07 1.402 1.082 3.26 1.655 5.223 1.69 3.924.07 8.106-2.037 9.686-5.431.26-.567.903-.933 1.524-.866.62.066 1.17.56 1.305 1.17.445 1.995 1.595 3.147 3.304 3.855 1.71.708 4.008.828 6.267.361 2.26-.468 4.475-1.51 6.078-2.963 1.603-1.453 2.589-3.252 2.659-5.393-12.4-5.432-3.653-19.742 4.503-15.07l-.348 1.56c-6.313-4.096-14.416 8.956-2.465 12.085 5.3.696 8.715-.978 10.977-3.798 2.263-2.82 3.296-6.959 3.077-11.072-.22-4.114-1.695-8.152-4.064-10.712-2.37-2.56-5.48-3.796-9.781-2.583-.83.223-1.78-.42-1.88-1.272-.325-2.781-1.677-4.71-3.628-6.078-1.952-1.367-4.554-2.1-7.217-2.164z"
          fill="#c51414"
        />
        <path
          d="M71.984 90.035c3.924 4.689 9.258 5.702 11.847 3.537 2.59-2.165 4.595-7.62 10.919-5.521-2.923-7.752 5.79-17.152 14.306-13.122 2.275-10.51 23.687-11.071 25.177 1.7 19.098-5.391 22.864 34.094 0 31.093-.337 10.36-19.08 14.24-21.274 4.406-3.784 8.131-18.933 9.236-20.52-1.754-10.175 4.455-19.197-7.873-20.455-20.34v.001z"
          fill="#fff"
        />
        <path
          d="M122 68.552c-2.661-.065-5.354.556-7.425 1.728-2.072 1.172-3.503 2.823-3.97 4.976-4.88 17.505 16.486 13.998 11.509 3.058l1.86-1.014c6.107 14.25-19.478 17.682-15.477-1.018-7.344-3.475-14.684 4.785-12.25 11.243.201.527.071 1.166-.32 1.573a1.536 1.536 0 01-1.56.383c-2.748-.913-4.052-.279-5.412.873-1.36 1.153-2.441 3.012-4.064 4.369-1.772 1.48-4.29 1.76-6.78 1.1-1.272-.335-2.556-.934-3.799-1.765 1.1 4.443 3.15 8.602 5.755 11.49 3.411 3.78 7.378 5.414 11.85 3.456-1.38-9.098 12.578-13.468 13.807-2.432l-1.734.118c-1.226-8.515-12.125-5.204-9.983 3.473.584 2.363 1.37 3.988 2.773 5.07 1.403 1.083 3.26 1.656 5.223 1.69 3.924.07 8.107-2.037 9.686-5.431.26-.567.904-.933 1.524-.866.62.066 1.171.56 1.306 1.17.445 1.995 1.595 3.147 3.304 3.855 1.71.708 4.008.829 6.267.361 2.26-.468 4.475-1.51 6.077-2.962 1.604-1.454 2.59-3.253 2.66-5.394-12.4-5.432-3.654-19.742 4.503-15.07l-.348 1.56c-6.314-4.096-14.417 8.956-2.465 12.085 5.3.696 8.714-.977 10.977-3.798 2.262-2.82 3.296-6.959 3.076-11.072-.22-4.114-1.694-8.152-4.064-10.712-2.37-2.56-5.48-3.796-9.78-2.583-.83.224-1.78-.42-1.88-1.272-.325-2.781-1.677-4.71-3.629-6.077-1.951-1.368-4.554-2.1-7.216-2.165z"
          fill="#c51414"
        />
        <g>
          <path
            d="M180.089 95.922c3.923 4.689 9.258 5.702 11.847 3.537 2.59-2.165 4.595-7.62 10.919-5.521-2.923-7.752 5.79-17.152 14.306-13.122 2.275-10.51 23.687-11.071 25.177 1.7 19.097-5.391 22.864 34.094 0 31.092-.337 10.36-19.081 14.24-21.274 4.407-3.784 8.131-18.933 9.236-20.52-1.754-10.175 4.455-19.197-7.873-20.455-20.34v.001z"
            fill="#fff"
          />
          <path
            d="M230.106 74.439c-2.662-.065-5.355.556-7.426 1.728-2.072 1.172-3.503 2.822-3.97 4.976-4.88 17.505 16.486 13.998 11.509 3.058l1.86-1.014c6.107 14.25-19.478 17.681-15.477-1.019-7.344-3.475-14.684 4.786-12.25 11.244.201.527.071 1.166-.32 1.573a1.536 1.536 0 01-1.56.383c-2.748-.913-4.052-.28-5.412.873-1.36 1.152-2.441 3.012-4.065 4.369-1.771 1.48-4.289 1.759-6.78 1.1-1.27-.335-2.555-.934-3.798-1.765 1.1 4.443 3.15 8.602 5.755 11.49 3.411 3.78 7.378 5.414 11.85 3.456-1.38-9.098 12.578-13.468 13.807-2.432l-1.734.118c-1.226-8.515-12.125-5.205-9.983 3.473.583 2.363 1.37 3.988 2.773 5.07 1.403 1.082 3.26 1.656 5.223 1.69 3.924.07 8.107-2.037 9.686-5.431.26-.567.904-.933 1.524-.866.62.066 1.171.56 1.306 1.17.445 1.995 1.594 3.147 3.304 3.855 1.71.708 4.008.829 6.267.361 2.26-.468 4.475-1.51 6.077-2.963 1.603-1.453 2.59-3.252 2.66-5.393-12.4-5.432-3.654-19.742 4.502-15.07l-.347 1.56c-6.314-4.096-14.417 8.956-2.465 12.085 5.3.696 8.714-.978 10.977-3.798 2.262-2.82 3.295-6.959 3.076-11.072-.22-4.114-1.694-8.152-4.064-10.712-2.37-2.56-5.48-3.796-9.78-2.583-.83.223-1.78-.42-1.881-1.272-.325-2.781-1.676-4.71-3.628-6.078-1.951-1.367-4.554-2.1-7.216-2.164z"
            fill="#c51414"
          />
        </g>
      </g>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="USA"
        transform="matrix(1.11714,0,0,1.11714,-6.89149,-0.542632)"
      >
        <clipPath id="_clip1">
          <circle cx="52.572" cy="146.483" r="34.723" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <g transform="matrix(0.156597,0,0,0.156597,15.687,103.961)">
            <path
              d="M256,0L512,0L512,64L480,96L512,128L512,192L480,224L512,256L512,320L480,352L512,384L512,448L256,480L0,448L0,384L32,352L0,320L0,256L256,0Z"
              style={{ fill: "rgb(238,238,238)", fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(0.156597,0,0,0.156597,15.687,103.961)">
            <path
              d="M224,64L512,64L512,128L224,128L224,64ZM224,192L512,192L512,256L256,256L224,192ZM0,320L512,320L512,384L0,384L0,320ZM0,448L512,448L512,512L0,512L0,448Z"
              style={{ fill: "rgb(216,0,39)", fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(0.156597,0,0,0.156597,15.687,103.961)">
            <rect
              x="0"
              y="0"
              width="256"
              height="256"
              style={{ fill: "rgb(0,82,180)", fillRule: "nonzero" }}
            />
          </g>
          <g transform="matrix(0.156597,0,0,0.156597,15.687,103.961)">
            <path
              d="M187,243L244,202L174,202L231,243L209,176L187,243ZM106,243L163,202L93,202L150,243L128,176L106,243ZM25,243L82,202L12,202L69,243L47,176L25,243ZM187,162L244,121L174,121L231,162L209,95L187,162ZM106,162L163,121L93,121L150,162L128,95L106,162ZM25,162L82,121L12,121L69,162L47,95L25,162ZM187,80L244,39L174,39L231,80L209,13L187,80ZM106,80L163,39L93,39L150,80L128,13L106,80ZM25,80L82,39L12,39L69,80L47,13L25,80Z"
              style={{ fill: "rgb(238,238,238)", fillRule: "nonzero" }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

const MemoAbstractProductUSA = React.memo(AbstractProductUSA);
export default MemoAbstractProductUSA;
