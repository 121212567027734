import * as React from "react";

function AbstractProductCAN(props) {
  return (
    <svg
      viewBox="0 0 300 300"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
      {...props}
    >
      <path
        d="M283.482 89.99c0-32.16-26.11-58.27-58.27-58.27H108.674c-32.16 0-58.27 26.11-58.27 58.27v120.018c0 32.16 26.11 58.27 58.27 58.27h116.538c32.16 0 58.27-26.11 58.27-58.27V89.991z"
        fill="#fcfcfc"
      />
      <path
        d="M273.896 83.851c0-19.08-15.49-34.57-34.57-34.57H96.26c-19.08 0-34.57 15.49-34.57 34.57v69.14c0 19.08 15.49 34.571 34.57 34.571h143.066c19.08 0 34.57-15.49 34.57-34.57V83.85z"
        fill="#416f6b"
      />
      <text
        x={101.707}
        y={229.28}
        fontFamily="'Raleway-Regular','Raleway'"
        fontSize={12}
        transform="translate(18.448 -16.764)"
      >
        {"Abstract Pr"}
        <tspan x={162.919} y={229.28}>
          {"o"}
        </tspan>
        {"duct"}
      </text>
      <text
        x={101.707}
        y={229.28}
        fontFamily="'Raleway-Regular','Raleway'"
        fontSize={12}
        fill="#3dafa5"
        transform="translate(-15.796 -112.494) scale(1.5254)"
      >
        {"$10.99"}
      </text>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="CAN"
        transform="matrix(1.11714,0,0,1.11714,-6.89149,-0.542632)"
      >
        <clipPath id="_clip1">
          <circle cx="52.572" cy="146.483" r="34.723" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <g
            id="Canada"
            transform="matrix(0.89514,0,0,0.89514,-2.99166,-1.23587)"
          >
            <g transform="matrix(0.189802,0,0,0.189802,13.0485,114.511)">
              <path
                d="M0,0L0,512L144,512L256,448L368,512L512,512L512,0L368,0L256,64L144,0L0,0Z"
                style={{ fill: "rgb(216,0,39)", fillRule: "nonzero" }}
              />
            </g>
            <g transform="matrix(0.189802,0,0,0.189802,13.0485,114.511)">
              <rect
                x="144"
                y="0"
                width="224"
                height="512"
                style={{ fill: "rgb(238,238,238)", fillRule: "nonzero" }}
              />
            </g>
            <g transform="matrix(0.189802,0,0,0.189802,13.0485,114.511)">
              <path
                d="M301,289L345,267L323,256L323,234L278,256L301,212L278,212L256,178L234,211L211,211L234,256L189,234L189,256L167,267L212,289L200,312L245,312L245,345L267,345L267,312L312,312L301,289Z"
                style={{ fill: "rgb(216,0,39)", fillRule: "nonzero" }}
              />
            </g>
          </g>
        </g>
      </g>
      <path
        d="M174.739 84.481l54.439 108.878H120.299l54.44-108.878z"
        fill="none"
        stroke="#3b3b3b"
        strokeWidth={2.67}
        transform="matrix(1.10631 0 0 .97343 -21.153 -22.764)"
      />
      <circle
        cx={175.632}
        cy={139.93}
        r={29.555}
        fill="none"
        stroke="#3b3b3b"
        strokeWidth={2.47}
        transform="matrix(1.12345 0 0 1.12345 -25.151 -24.95)"
      />
      <path fill="#3b3b3b" d="M171.061 59.472h2.2v105.986h-2.2z" />
      <g>
        <path
          d="M86.929 74.365l-9.881 20.078 13.705-3.975-8.493 17.259M121.816 61.287l-9.881 20.078 13.705-3.974-8.493 17.258M220.582 94.648l-9.88-20.078 13.705 3.975-8.493-17.259M255.47 107.726l-9.882-20.078 13.706 3.975-8.493-17.259"
          fill="none"
          stroke="#a39964"
          strokeWidth={2.78}
        />
      </g>
    </svg>
  );
}

const MemoAbstractProductCAN = React.memo(AbstractProductCAN);
export default MemoAbstractProductCAN;
