import React, { useState } from "react";
import translations from "./translations/en.json";
import Retail from "./components/Retail/Retail";
import Localization from "./components/Localization/Localization";
import TimelineShopify from "../../assets/TimelineShopify";
import TimelineManulife from "../../assets/TimelineManulife";
import TimelineNuance from "../../assets/TimelineNuance";
import "./Work.scss";
import SlideTransition from "../SlideTransition/SlideTransition";

enum Teams {
  Localization = "Localization",
  Retail = "Retail",
}

function Work() {
  const teamSelectedClass = "teamSelected";
  const [selectedTeam, setSelectedTeam] = useState(Teams.Localization);

  const workTeamClass = (team: Teams) => {
    return team === selectedTeam ? teamSelectedClass : "";
  };

  const selectTeam = (team: Teams) => {
    setSelectedTeam(team);
  };

  const teamContent = () => {
    switch (selectedTeam) {
      case Teams.Localization:
        return (
          <>
            <Localization />
          </>
        );
      case Teams.Retail:
        return <Retail />;
    }
  };

  const content = (
    <div className="work-container">
      <div className="work-team">
        <button
          className={workTeamClass(Teams.Localization)}
          onClick={() => selectTeam(Teams.Localization)}
        >
          {Teams.Localization.toUpperCase()}
        </button>
        <button
          className={workTeamClass(Teams.Retail)}
          onClick={() => selectTeam(Teams.Retail)}
        >
          {Teams.Retail.toUpperCase()}
        </button>
      </div>
      <div className="flex-break"></div>
      {teamContent()}
      <div className="flex-break"></div>
      <div className="work-timeline">
        <h1 className="work-timeline__header">
          <span className="gold-underline">{translations.timeLineHeader}</span>
        </h1>
        <div className="work-timeline-content">
          <div className="work-timeline-content__shopify">
            <TimelineShopify className="work-timeline-icon" />
            <h2>{translations.timeLineShopifyTitle}</h2>
            <h3>{translations.timeLineShopifyYear}</h3>
            <p className="work-highlight">
              {translations.timeLineShopfiyContent}
            </p>
          </div>
        </div>
        <div className="work-timeline-content">
          <div className="work-timeline-content__shopify-intern">
            <TimelineShopify className="work-timeline-icon" />
            <h2>{translations.timeLineShopifyTitle}</h2>
            <h3>{translations.timeLineShopifyInternYear}</h3>
            <p className="work-highlight">
              {translations.timeLineShopfiyInternContent}
            </p>
          </div>
        </div>
        <div className="work-timeline-content">
          <div className="work-timeline-content__manulife">
            <TimelineManulife className="work-timeline-icon" />
            <h2>{translations.timeLineManulifeTitle}</h2>
            <h3>{translations.timeLineManulifeYear}</h3>
            <p className="work-highlight">
              {translations.timeLineManulifeContent}
            </p>
          </div>
        </div>
        <div className="work-timeline-content">
          <div className="work-timeline-content__manulife">
            <TimelineNuance className="work-timeline-icon" />
            <h2>{translations.timeLineNuanceTitle}</h2>
            <h3>{translations.timeLineNuanceYear}</h3>
            <p className="work-highlight">
              {translations.timeLineNuanceContent}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return <SlideTransition>{content}</SlideTransition>;
}

export default Work;
