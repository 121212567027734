export enum Paths {
  Me = "/",
  Work = "/work",
  Projects = "/projects",
  Design = "/design",
}

export function isActivePath(currentPath, path) {
  return currentPath === path;
}
