import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Me from "../components/Me/Me";
import Work from "../components/Work/Work";
import Projects from "../components/Projects/Projects";
import Design from "../components/Design/Design";

function Main() {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route path="/work" element={<Work />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/design" element={<Design />} />
      <Route path="/" element={<Me />} />
    </Routes>
  );
}

export default Main;
