import * as React from "react";

function SocialGithub(props) {
  return (
    <svg data-name="Layer 2" viewBox="0 0 98.19 95.76" {...props}>
      <path
        d="M49.09 0a49.1 49.1 0 00-15.52 95.68c2.46.45 3.35-1.07 3.35-2.37s0-4.25-.06-8.35c-13.66 3-16.54-6.58-16.54-6.58-2.23-5.67-5.45-7.18-5.45-7.18-4.46-3 .33-3 .33-3 4.93.35 7.52 5.06 7.52 5.06 4.38 7.51 11.5 5.34 14.29 4.08a10.53 10.53 0 013.08-6.55c-10.9-1.24-22.36-5.45-22.36-24.26a19 19 0 015.05-13.18c-.5-1.24-2.19-6.23.48-13 0 0 4.12-1.32 13.5 5a46.6 46.6 0 0124.58 0c9.38-6.35 13.49-5 13.49-5 2.68 6.76 1 11.75.49 13a18.94 18.94 0 015 13.18c0 18.86-11.48 23-22.42 24.22 1.76 1.52 3.33 4.51 3.33 9.09 0 6.57-.06 11.86-.06 13.47 0 1.31.89 2.84 3.38 2.36A49.1 49.1 0 0049.09 0z"
        fill="#FFFFFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

const MemoSocialGithub = React.memo(SocialGithub);
export default MemoSocialGithub;
