import * as React from "react";

function TimelineManulife(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 182.8 182.8"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#00ac5b}"}</style>
      <g id="prefix__circle">
        <g id="prefix__circle-2">
          <circle
            cx={91.4}
            cy={91.4}
            r={89.9}
            fill="none"
            stroke="#edc21b"
            strokeWidth={3}
            strokeMiterlimit={10}
            id="prefix__Layer_2_1_"
          />
        </g>
      </g>
      <g id="prefix__shopify">
        <g id="prefix__layer1">
          <g id="prefix__g9655">
            <path
              id="prefix__path193"
              className="prefix__st1"
              d="M119.2 59v83.3l18.5-18.5V40.5L119.2 59z"
            />
            <path
              id="prefix__path197"
              className="prefix__st1"
              d="M82.2 142.3l18.5-18.5V40.5L82.2 59v83.3z"
            />
            <path
              id="prefix__path201"
              className="prefix__st1"
              d="M45.1 142.3l18.5-18.5V40.5L45.1 59v83.3z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

const MemoTimelineManulife = React.memo(TimelineManulife);
export default MemoTimelineManulife;
