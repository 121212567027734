import * as React from "react";

function TimelineNuance(props) {
  return (
    <svg viewBox="0 0 182.8 182.8" {...props}>
      <circle
        cx={91.4}
        cy={91.4}
        r={89.9}
        fill="none"
        stroke="#edc21b"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M64 74c15.4 0 34.7 14.2 34.7 14.2v-1.5s-18.9-30.9-54-30.9H33.8c-2.4-.1-4.5 1.8-4.6 4.2v29.3c.1 2.4 2.2 4.3 4.6 4.2H64V74z"
        fill="#7fc31c"
      />
      <path
        d="M115.3 108.8c-15.4 0-34.7-14.2-34.7-14.2v1.5s18.9 30.9 54 30.9h10.8c2.4.1 4.5-1.8 4.6-4.2V93.5c-.1-2.4-2.1-4.3-4.6-4.2h-30.2v19.5z"
        fill="#469c23"
      />
    </svg>
  );
}

const MemoTimelineNuance = React.memo(TimelineNuance);
export default MemoTimelineNuance;
