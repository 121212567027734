import React, { useState, useEffect } from "react";
import translations from "./translations/en.json";
import { ProjectsList } from "../../utils/projects";
import { useLocation } from "react-router-dom";

import ProjectsArsenal from "../../assets/ProjectsArsenal";
import ProjectsWeather from "../../assets/ProjectsWeather";
import ProjectsWatslit from "../../assets/ProjectsWatslit";
import ProjectsTagify from "../../assets/ProjectsTagify";
import ProjectsTetris from "../../assets/ProjectsTetris";
import ProjectsErModel from "../../assets/ProjectsErModel";
import ComingSoonSpinner from "../../assets/ComingSoonSpinner";

import { LocationState } from "../types";
import SlideTransition from "../SlideTransition/SlideTransition";
import Arsenal from "./components/Arsenal/Arsenal";
import Weather from "./components/Weather/Weather";
import WatsLit from "./components/WatsLit/WatsLit";
import Tagify from "./components/Tagify/Tagify";
import Tetris from "./components/Tetris/Tetris";
import ERModeler from "./components/ERModeler/ERModeler";

import "./Projects.scss";

const ENTER_KEY = 13;

function Projects() {
  const [projectToRender, setProjectToRender] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (!location.state) return;

    const { resetState } = location.state as LocationState;
    if (resetState) {
      setProjectToRender("");
    }
  }, [location]);

  const onKeyDownHandler = (e, project) => {
    if (e.keyCode === ENTER_KEY) {
      setProjectToRender(project);
    }
  };

  const projectsList = (
    <>
      <div className="main-projects-list">
        <h1 className="main-projects__header">
          <span className="blue-underline">{translations.projects}</span>
        </h1>
        <ComingSoonSpinner className="projects-coming-soon" />
      </div>
      <h1 className="projects__header">
        <span className="blue-underline">{translations.archivedProjects}</span>
      </h1>
      <p className="projects__description">
        {translations.projectsDescription}
      </p>
      <div className="projects__list">
        <div
          className="projects-list-arsenal projects-focus"
          aria-label="Show information for arsenal project"
          tabIndex={0}
          onClick={() => setProjectToRender(ProjectsList.Arsenal)}
          onKeyDown={(e) => {
            onKeyDownHandler(e, ProjectsList.Arsenal);
          }}
        >
          <ProjectsArsenal className="projects-icon" />
          <h2>
            <span className="blue-underline">{translations.arsenalApp}</span>
          </h2>
          <span className="projects-highlight">
            {translations.arsenalAppStack}
          </span>
        </div>
        <div
          className="projects-list-weather projects-focus"
          aria-label="Show information for weather project"
          tabIndex={0}
          onClick={() => setProjectToRender(ProjectsList.Weather)}
          onKeyDown={(e) => {
            onKeyDownHandler(e, ProjectsList.Weather);
          }}
        >
          <ProjectsWeather className="projects-icon" />
          <h2>
            <span className="blue-underline">{translations.weatherApp}</span>
          </h2>
          <span className="projects-highlight">
            {translations.weatherAppStack}
          </span>
        </div>
        <div
          className="projects-list-watslit projects-focus"
          aria-label="Show information for watslit project"
          tabIndex={0}
          onClick={() => setProjectToRender(ProjectsList.WatsLit)}
          onKeyDown={(e) => {
            onKeyDownHandler(e, ProjectsList.WatsLit);
          }}
        >
          <ProjectsWatslit className="projects-icon" />
          <h2>
            <span className="blue-underline">{translations.watsLit}</span>
          </h2>
          <span className="projects-highlight">
            {translations.watsLitStack}
          </span>
        </div>
        <div
          className="projects-list-tagify projects-focus"
          aria-label="Show information for tagify project"
          tabIndex={0}
          onClick={() => setProjectToRender(ProjectsList.Tagify)}
          onKeyDown={(e) => {
            onKeyDownHandler(e, ProjectsList.Tagify);
          }}
        >
          <ProjectsTagify className="projects-icon" />
          <h2>
            <span className="blue-underline">{translations.tagify}</span>
          </h2>
          <span className="projects-highlight">{translations.tagifyStack}</span>
        </div>
        <div
          className="projects-list-tetris projects-focus"
          aria-label="Show information for tetris project"
          tabIndex={0}
          onClick={() => setProjectToRender(ProjectsList.Tetris)}
          onKeyDown={(e) => {
            onKeyDownHandler(e, ProjectsList.Tetris);
          }}
        >
          <ProjectsTetris className="projects-icon" />
          <h2>
            <span className="blue-underline">{translations.tetris}</span>
          </h2>
          <span className="projects-highlight">{translations.tetrisStack}</span>
        </div>
        <div
          className="projects-list-er-modeler projects-focus"
          aria-label="Show information for er modeler project"
          tabIndex={0}
          onClick={() => setProjectToRender(ProjectsList.ERModeler)}
          onKeyDown={(e) => {
            onKeyDownHandler(e, ProjectsList.ERModeler);
          }}
        >
          <ProjectsErModel className="projects-icon" />
          <h2>
            <span className="blue-underline">{translations.ERModel}</span>
          </h2>
          <span className="projects-highlight">
            {translations.ERModelStack}
          </span>
        </div>
      </div>
    </>
  );

  const backToProjectsButton = (
    <button
      className="btn projects-back-btn"
      onClick={() => {
        setProjectToRender("");
      }}
    >
      {translations.backToList.toLocaleUpperCase()}
    </button>
  );

  function renderArchiveProjectDetailsHandler() {
    window.scrollTo({ top: 0 });

    switch (projectToRender) {
      case ProjectsList.Arsenal:
        return <Arsenal backToProjectsButton={backToProjectsButton} />;
      case ProjectsList.Weather:
        return <Weather backToProjectsButton={backToProjectsButton} />;
      case ProjectsList.WatsLit:
        return <WatsLit backToProjectsButton={backToProjectsButton} />;
      case ProjectsList.Tagify:
        return <Tagify backToProjectsButton={backToProjectsButton} />;
      case ProjectsList.Tetris:
        return <Tetris backToProjectsButton={backToProjectsButton} />;
      case ProjectsList.ERModeler:
        return <ERModeler backToProjectsButton={backToProjectsButton} />;
      default:
        return projectsList;
    }
  }

  return (
    <SlideTransition>
      <div className="projects-container">
        {renderArchiveProjectDetailsHandler()}
      </div>
    </SlideTransition>
  );
}

export default Projects;
