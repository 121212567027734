import React from "react";
import { animated, useTransition } from "react-spring";

type Props = {
  children?: React.ReactNode;
};

function SlideTransition({ children }: Props) {
  const transitions = useTransition(true, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
  });

  return transitions((styles) => (
    <animated.div style={styles}>{children}</animated.div>
  ));
}

export default SlideTransition;
