import * as React from "react";

function Ipad(props) {
  return (
    <svg viewBox="0 0 182.7 182.7" {...props}>
      <circle
        fill="none"
        stroke="#EBA4D8"
        strokeWidth={3}
        strokeMiterlimit={10}
        cx={91.4}
        cy={91.4}
        r={89.9}
      />
      <path
        fill="#5E5D5E"
        d="M153.9 140.7V47.8s.7-3.3-5.2-3.3H94 34c-5.9 0-5.2 3.3-5.2 3.3V141.1s-.7 3.3 5.2 3.3h114.7c5.9 0 5.2-3.3 5.2-3.3v-.4zm-8.1 1.2H93.9 37c-5.6 0-4.9-3.2-4.9-3.2V50.2S31.4 47 37 47h108.8c5.6 0 4.9 3.2 4.9 3.2V138.7s.7 3.2-4.9 3.2z"
      />
      <path
        fill="#FCFCFC"
        d="M114.8 135.4h-46s-3.3 0-3.3 1.2c0 1.1 2.7 1 2.7 1h47.1s2.7.1 2.7-1c.1-1.1-3.2-1.2-3.2-1.2z"
      />
      <g fill="#FCFCFC">
        <path d="M142.9 88.2c-1.4-2-4-1.3-4-1.3l-22 11.2-25.1 12.8 2.4 6.2 22-11.2 25.1-12.8s3-3 1.6-4.9zM90.4 111.2l2.5 6.2-7.6-.1 5-5.9" />
      </g>
      <path
        className="ipad__left-eye"
        fill="#EA92D8"
        d="M49.7 77.2c.2-.8 1.4-.6 1.9-.4.5.1.9.3 1.4.5.5.2.9.5 1.4.6 1 .2 1.7-.5 2-1.4.4-1 .8-2 1-3 .2-.9-1.2-1.3-1.4-.4-.2 1-.5 1.9-.9 2.8-.1.2-.2.5-.4.5s-.5-.2-.7-.3c-.3-.1-.5-.2-.8-.3-.9-.4-1.9-.7-2.9-.6-1 .1-1.9.7-2.1 1.7-.2.9 1.3 1.3 1.5.3z"
      />
      <g>
        <path
          className="ipad__right-eye"
          fill="#EA92D8"
          d="M69.1 71.9c.3-1 .6-2.2 1.2-3 .2-.2.3-.4.5-.6.3-.2.3-.1.6 0 .5.3 1 .7 1.6 1 .6.3 1.2.5 1.8.6.7.1 1.2-.2 1.5-.9.6-1.3 1.1-2.7 1.6-4.1.1-.4-.2-.8-.5-.9-.4-.1-.8.1-.9.5-.3.7-.5 1.4-.8 2.1-.1.3-.3.6-.4 1-.1.3-.3.6-.3.8-.1.2-.1.1.2 0-.1 0-.1 0-.2-.1-.1 0-.3-.1-.4-.1-.3-.1-.6-.2-.9-.4-.6-.3-1-.7-1.6-1-1.3-.7-2.4.3-3.1 1.3-.7 1-1 2.1-1.3 3.2-.3 1.1 1.1 1.5 1.4.6z"
        />
      </g>
      <g>
        <path
          className="ipad__nose"
          fill="#EA92D8"
          d="M63.6 83.2c0 .8-.1 1.6-.1 2.4 0 .4-.1.8-.1 1.3 0 .4.1.9.4 1.2.5.3 1 .2 1.4 0 .4-.2.7-.4 1.1-.6.7-.4 1.5-.8 2.2-1.2.3-.2.5-.7.3-1-.2-.4-.7-.5-1-.3-.6.3-1.3.7-1.9 1-.3.2-.7.3-1 .5-.2.1-.3.2-.5.2-.1 0-.1 0 0 0h-.1c.1 0 .2 0 .3.1.1 0 .1.1.2.2 0 .1 0 .1 0 0v-.1-.5c0-.3 0-.7.1-1 0-.7.1-1.4.1-2.1 0-.4-.4-.8-.8-.8-.3-.1-.6.2-.6.7z"
        />
      </g>
      <g>
        <path
          className="ipad__mouth"
          fill="#EA92D8"
          d="M55.2 99.1c2.3 3.1 5.9 4.3 9.6 4.8 4.1.5 8.2.2 11.9-1.9 7.2-4.2 9.8-13.3 6.2-20.8-.4-.9-1.7-.1-1.3.8 3.3 6.7.9 15-5.6 18.7-3.3 1.9-6.9 2.2-10.6 1.8-3.3-.4-6.8-1.4-8.8-4.1-.7-.9-2-.1-1.4.7z"
        />
      </g>
    </svg>
  );
}

const MemoIpad = React.memo(Ipad);
export default MemoIpad;
