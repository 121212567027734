import * as React from "react";

function CodeMajor(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <path
        d="M2.707 9.707A.996.996 0 003 9V5a1 1 0 011-1 1 1 0 000-2C2.346 2 1 3.346 1 5v3.586l-.707.707a.999.999 0 000 1.414l.707.707V15c0 1.654 1.346 3 3 3a1 1 0 000-2 1 1 0 01-1-1v-4a.996.996 0 00-.293-.707L2.414 10l.293-.293zm17.217-.09a1.001 1.001 0 00-.217-.324L19 8.586V5c0-1.654-1.346-3-3-3a1 1 0 100 2 1 1 0 011 1v4a.997.997 0 00.293.707l.293.293-.293.293A.996.996 0 0017 11v4a1 1 0 01-1 1 1 1 0 100 2c1.654 0 3-1.346 3-3v-3.586l.707-.707a1.001 1.001 0 00.217-1.09zm-7.227-4.333a1.002 1.002 0 00-1.63.346l-3.996 8a.999.999 0 00.56 1.299 1.006 1.006 0 001.302-.557l3.995-8a.997.997 0 00-.23-1.088z"
        fill="#5C5F62"
      />
    </svg>
  );
}

const MemoCodeMajor = React.memo(CodeMajor);
export default MemoCodeMajor;
