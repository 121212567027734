import * as React from "react";

function AdobeMonitor(props) {
  return (
    <svg viewBox="0 0 130.32 116.78" {...props}>
      <path
        d="M75.93 106.92V92.45H54.6v14.44c-6.92.89-11.62 2.63-11.62 4.63 0 2.91 9.92 5.26 22.17 5.26s22.16-2.35 22.16-5.26c0-1.98-4.59-3.7-11.38-4.6z"
        fill="#cac7c0"
      />
      <path
        d="M130.32 75.45v12.64a5 5 0 01-5 5H5a5 5 0 01-5-5V75.67"
        fill="#cac7c0"
      />
      <path
        d="M0 77.78V5a5 5 0 015-5h120.32a5 5 0 015 5v72.55"
        fill="#575959"
      />
      <path
        className="design-adobe-right-side"
        fill="#9c6ade"
        d="M7.35 4.33l115.12-.57.72 67.37L7.35 4.33z"
      />
      <path
        className="design-adobe-left-side"
        fill="#b492ea"
        d="M123.1 71.13L7.99 71.7 7.27 4.33l115.83 66.8z"
      />
      <circle cx={65.27} cy={83.96} r={4.67} fill="#908f8f" />
      <circle cx={65.27} cy={83.96} r={2.89} fill="#575959" />
      <g className="design-adobe-ai">
        <rect
          x={44.59}
          y={15.85}
          width={44.33}
          height={44.33}
          rx={5}
          fill="#ff5722"
        />
        <path fill="#1c0802" d="M48.66 19.92h36.19v36.19H48.66z" />
        <g fill="#ff5722">
          <path d="M60.92 40.71l-1.72 5.23H57l5.63-16.58h2.59l5.67 16.63H68.6l-1.78-5.23zm5.46-1.67l-1.63-4.79c-.37-1.09-.62-2.07-.86-3-.25 1-.52 2-.84 3l-1.63 4.81zM75.61 30.65a1.35 1.35 0 01-2.69 0 1.32 1.32 0 011.35-1.36 1.29 1.29 0 011.34 1.36zm-2.42 15.29V34h2.17v11.94z" />
        </g>
      </g>
      <g className="design-adobe-ps">
        <rect
          x={44.59}
          y={15.85}
          width={44.33}
          height={44.33}
          rx={5}
          fill="#28c1c9"
        />
        <path fill="#021419" d="M48.66 19.92h36.19v36.19H48.66z" />
        <g fill="#28c1c9">
          <path d="M57.46 29.51a25.1 25.1 0 014.12-.32 6.87 6.87 0 014.66 1.38 4.49 4.49 0 011.46 3.48 4.91 4.91 0 01-1.29 3.56 6.91 6.91 0 01-5.08 1.82 7.1 7.1 0 01-1.73-.15v6.66h-2.14zm2.14 8a7.45 7.45 0 001.78.17c2.59 0 4.17-1.25 4.17-3.55s-1.55-3.24-3.92-3.24a8.87 8.87 0 00-2 .18zM69.67 43.72a5.69 5.69 0 002.86.87c1.58 0 2.32-.79 2.32-1.78s-.61-1.6-2.22-2.19c-2.14-.77-3.15-1.95-3.15-3.39 0-1.92 1.55-3.5 4.12-3.5a6 6 0 012.93.74L76 36.05a4.61 4.61 0 00-2.45-.69c-1.29 0-2 .74-2 1.63s.71 1.43 2.27 2c2.07.79 3.13 1.83 3.13 3.6 0 2.1-1.63 3.58-4.47 3.58a6.85 6.85 0 01-3.35-.81z" />
        </g>
      </g>
    </svg>
  );
}

const MemoAdobeMonitor = React.memo(AdobeMonitor);
export default MemoAdobeMonitor;
