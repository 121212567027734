import React from "react";
import ProjectsTagify from "../../../../assets/ProjectsTagify";
import Tagify1 from "../../../../assets/projects/tagify1.png";
import Tagify2 from "../../../../assets/projects/tagify2.png";
import Tagify3 from "../../../../assets/projects/tagify3.png";

import translations from "./translations/en.json";

type Props = {
  backToProjectsButton: React.ReactNode;
};

function Tagify({ backToProjectsButton }: Props) {
  return (
    <div className="project-info">
      <ProjectsTagify className="projects-icon" />
      <h1 className="project-info__title">
        <span className="blue-underline">
          {translations.tagify.toLocaleUpperCase()}
        </span>
      </h1>
      <p
        className="project-info__demo"
        dangerouslySetInnerHTML={{
          __html: translations.tagifyProject.codeAndDemo,
        }}
      ></p>
      <h2 className="project-info__goals">
        <span className="projects-highlight">{translations.projectGoals}</span>
      </h2>
      <ul className="project-info__goals-list">
        <li>{translations.tagifyProject.goals[1]}</li>
        <li>{translations.tagifyProject.goals[2]}</li>
      </ul>
      <p className="project-info__reflections1">
        {translations.tagifyProject.reflections1}
      </p>
      <p className="project-info__reflections1">
        {translations.tagifyProject.reflections2}
      </p>
      <h2 className="project-info__mock-ups">
        <span className="projects-highlight">{translations.screenshots}</span>
      </h2>
      <div className="project-info__tagify-images">
        <img
          className="project-info__image"
          src={Tagify1}
          alt="Tagify Screenshots"
          onClick={() => {
            window.open(Tagify1, "_blank");
          }}
        />
        <img
          className="project-info__image"
          src={Tagify2}
          alt="Tagify Screenshots"
          onClick={() => {
            window.open(Tagify2, "_blank");
          }}
        />
        <img
          className="project-info__image"
          src={Tagify3}
          alt="Tagify Screenshots"
          onClick={() => {
            window.open(Tagify3, "_blank");
          }}
        />
      </div>
      {backToProjectsButton}
    </div>
  );
}

export default Tagify;
