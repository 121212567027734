import * as React from "react";

function ProjectsArsenal(props) {
  return (
    <svg viewBox="0 0 182.8 182.8" {...props}>
      <g data-name="circle">
        <circle
          cx={91.4}
          cy={91.4}
          r={89.9}
          fill="none"
          stroke="#47C1BF"
          strokeMiterlimit={10}
          strokeWidth={3}
          data-name="Layer 2"
        />
      </g>
      <path
        d="M151.69 125.8V57.05l-60.29-35-60.29 35v68.8l60.29 35z"
        fill="#e32219"
      />
      <path
        fill="#fff"
        d="M141.37 62.95L91.4 33.98 41.43 62.95v56.91l49.97 28.96 49.97-28.96V62.95z"
      />
      <path
        fill="#e32219"
        d="M127.18 66.77l4.93 11.66L91.4 61.95l-26.5 9.63-8.53 19.45 8.43 19.24.14.24 26.46 9.59 40.57-16.37.06.09-4.85 11.46-35.78 12.47-33.38-9.38-13.17-27.34 13.17-27.37L91.4 54.3l35.78 12.47z"
      />
      <path
        fill="#e32219"
        d="M70.03 71.55l12.07-4.37-16.28 41.09-7.48-17.07 11.69-19.65zM92.05 75.67a15 15 0 018.2 2.72 14.44 14.44 0 01-2.66 3 3.86 3.86 0 01-5.54-.34v-5.38zM104.02 56.88l-8.59-2.97-4.03-10.2-3.93 9.95-8.12 2.26L91.4 35.69l12.62 21.19zM82.51 78.39a15 15 0 018.2-2.72v5.38a3.82 3.82 0 01-5.5.33 13.44 13.44 0 01-2.7-2.99z"
      />
      <path
        d="M98.05 82.77a9.76 9.76 0 013.79 7.63h-3.15a3.8 3.8 0 00-2.24-1.89 13 13 0 00-5.13-.86 12.5 12.5 0 00-5 .86 3.75 3.75 0 00-2.22 1.89h-3.05a9.59 9.59 0 013.79-7.63 4.94 4.94 0 006.57-.51 5 5 0 006.64.51z"
        fill="#e32219"
      />
      <path
        d="M103.14 91.12a11 11 0 00-4-9.2 22.89 22.89 0 002.28-2.62c.33.28.68.59 1 .93a15.52 15.52 0 010 21.86c-.34.31-.69.6-1 .92a22.59 22.59 0 00-2.28-2.65 11 11 0 004-9.24z"
        fill="#e32219"
      />
      <path
        d="M98.69 91.77h3.11a9.8 9.8 0 01-3.75 7.65 5 5 0 00-6.61.51 4.94 4.94 0 00-6.57-.51 9.62 9.62 0 01-3.82-7.65h3.07a3.77 3.77 0 002.22 1.91 14.87 14.87 0 0010.08 0 3.82 3.82 0 002.27-1.91z"
        fill="#e32219"
      />
      <path
        d="M86.9 89.79a11.29 11.29 0 014.42-.74 12.37 12.37 0 014.62.77c.91.37 1.42.82 1.52 1.3-.1.49-.61.94-1.52 1.31a12.47 12.47 0 01-4.48.79 12.77 12.77 0 01-4.56-.79c-.91-.37-1.42-.82-1.52-1.31.1-.51.61-.96 1.52-1.33zM85.21 100.86c1.88-1.48 3.75-1.34 5.5.35v5.33a15.14 15.14 0 01-8.2-2.76 12.72 12.72 0 012.7-2.92zM92.05 106.54v-5.33a3.83 3.83 0 015.54-.35 13.6 13.6 0 012.66 2.92 15.14 15.14 0 01-8.2 2.76zM81.45 102.96c-.37-.32-.72-.61-1-.92a15.48 15.48 0 010-21.86 7 7 0 011-.93 19.5 19.5 0 002.22 2.62 11 11 0 00-4 9.2 11 11 0 004 9.19 19.31 19.31 0 00-2.22 2.7zM60.81 120.97l-2.85 7.19-4.17-13.19-12.22 4.34 8.29-13.93 6.96 14.45 3.99 1.14zM131.07 102.21l-14.21 5.74-15.97-40.3 12.61 5.09 17.57 29.47zM128.5 116.66l-3.66 11.5-3.54-8.98 7.16-2.52h.04zM133.13 105.69l8.12 13.62-12.07-4.26 3.95-9.36z"
        fill="#e32219"
      />
    </svg>
  );
}

const MemoProjectsArsenal = React.memo(ProjectsArsenal);
export default MemoProjectsArsenal;
