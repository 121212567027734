import * as React from "react";

function Controller(props) {
  return (
    <svg viewBox="0 0 182.7 182.7" {...props}>
      <circle
        fill="none"
        stroke="#EBA4D8"
        strokeWidth={3}
        strokeMiterlimit={10}
        cx={91.4}
        cy={91.4}
        r={89.9}
      />
      <g fill="#1F1E25">
        <path d="M37.8 53.5s-.1-2.8.5-3.8 5.6-5.2 9.3-5.3c3.7 0 8.3-.7 8.4.8.2 1.5.3 3.7.3 3.7l-18.6 5 .1-.4zM146.2 53.5s.1-2.8-.5-3.8-5.6-5.2-9.3-5.3c-3.7 0-8.3-.7-8.4.8-.2 1.5-.3 3.7-.3 3.7l18.6 5-.1-.4z" />
      </g>
      <path
        fill="#E7E8F3"
        d="M160.1 86.4c-10.7-38.2-12.4-34.8-12.4-34.8s-41-6.4-55.6-6.4h-1.4c-14.6 0-55.6 6.4-55.6 6.4s-1.7-3.4-12.4 34.8c-8.2 38.3 5.7 49.9 5.7 49.9.6.7 1.6 1.2 2.7 1.5C28.7 134 62.3 74 63.5 73c1.2-1.1-2.1-23.4-2.1-23.4s0-.4.4-.4c.4-.1.7.3.7.3s1.3 6.2 3.2 16.4c1.9 10.1 6.5 10.8 6.5 10.8l19.1.1 19.1-.1s4.7-.7 6.5-10.8c1.9-10.1 3.2-16.4 3.2-16.4s.2-.4.7-.3c.4.1.4.4.4.4S117.9 72 119.1 73c1.2 1 35.4 62.2 32.4 64.9h.1c1.3-.3 2.4-.8 3-1.6-.2 0 13.7-11.7 5.5-49.9z"
      />
      <path d="M118.9 73c-1.2-1.1 2.1-23.4 2.1-23.4s0-.4-.4-.4c-.4-.1-.7.3-.7.3s-1.3 6.2-3.2 16.4c-1.9 10.1-6.5 10.8-6.5 10.8l-19.1.1-19.1-.1s-4.7-.7-6.5-10.8c-1.9-10.1-3.2-16.4-3.2-16.4s-.2-.4-.7-.3c-.4.1-.4.4-.4.4S64.5 72 63.3 73c-1.2 1-34.7 61-32.5 64.8.1.1.2.2.3.2 0 0 5.6 1.3 8.7.1 1.1-4.1 10.4-37.9 24.9-32.8h52.8c14.5-5.1 23.8 28.7 24.9 32.8 3 1.2 8.7-.1 8.7-.1.1 0 .2 0 .3-.1 2.9-2.7-31.3-63.9-32.5-64.9z" />
      <path
        fill="#E7E8F3"
        stroke="#514F4F"
        strokeWidth={0.25}
        strokeMiterlimit={10}
        d="M46.6 59.2s3.4 0 4.5 2c-.2 2.8-.3 4.7-.4 5s-.7 1.3-2.3 2.4c-1.6 1.1-1.8 1.3-1.8 1.3s-3.8-2.5-4-3.4-.5-5.2-.5-5.2 1.1-2 4.5-2.1zM46.6 83.4s-3.4 0-4.5-2c.2-2.8.3-4.7.4-5 .1-.3.7-1.3 2.3-2.4 1.6-1.1 1.8-1.3 1.8-1.3s3.8 2.5 4 3.4.5 5.2.5 5.2-1.1 2.1-4.5 2.1zM58.6 71.4s0 3.4-2 4.5c-2.8-.2-4.7-.3-5-.4-.3-.1-1.3-.7-2.4-2.3-1.1-1.6-1.3-1.8-1.3-1.8s2.5-3.8 3.4-4c.9-.2 5.2-.5 5.2-.5s2 1 2.1 4.5zM34.4 71.4s0-3.4 2-4.5c2.8.2 4.7.3 5 .4.3.1 1.3.7 2.4 2.3 1.1 1.6 1.3 1.8 1.3 1.8s-2.5 3.8-3.4 4c-.9.2-5.2.5-5.2.5s-2.1-1-2.1-4.5z"
      />
      <path
        fill="#514F4F"
        d="M47.3 61h-1.4l.7-1.1zM56.9 72.2v-1.5l1.1.7zM36.1 70.6v1.5l-1.1-.7zM45.9 81.7h1.4l-.7 1z"
      />
      <g
        fill="#E2E5ED"
        stroke="#514F4F"
        strokeWidth={0.25}
        strokeMiterlimit={10}
      >
        <ellipse
          transform="rotate(-15.106 58.145 56.08)"
          cx={58.1}
          cy={56.1}
          rx={1.3}
          ry={3.1}
        />
        <ellipse
          transform="rotate(-74.894 123.595 56.073)"
          cx={123.6}
          cy={56.1}
          rx={3.1}
          ry={1.3}
        />
      </g>
      <g fill="#0F2FA5">
        <path d="M61.3 49.5L63 62.4s2.8 11.3 4.3 11.2c1.5-.1.9-.2.9-.2s-4.6-16-4.4-16.6c-.2-1.8-1.1-5.7-1.1-5.7l-.4-1.8s-.1-.3-.7-.3-.3.5-.3.5zM121 49.5l-1.7 12.9s-2.8 11.3-4.3 11.2c-1.5-.1-.9-.2-.9-.2s4.6-16 4.4-16.6c.2-1.8 1.1-5.7 1.1-5.7l.4-1.8s.1-.3.7-.3.3.5.3.5z" />
      </g>
      <g>
        <image
          className="controller-blue-glow"
          overflow="visible"
          width={79}
          height={43}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE8AAAArCAYAAAA5UdXKAAAACXBIWXMAAAsSAAALEgHS3X78AAAA GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABA5JREFUeNrsWl1zozAMxMbQ9v3+ /y/sc1MItq+ZyMmyyHyk09a+KzMe0gLCWlYrWdA0v9vv9hOb2XVWfL2eZ/7EfxqN5OcNnXV/zQ5D fE7ca7wywDQs4pqfZsOYyYAXb79rBvDqK/tpyM+YA9FkgLNyzCoABjEWboZrBPAOnCV/kSSB/J0B 6BSzFkYrw4JBL4a8jMtE6grhOXAt+WkIuEn8NDPJmoE3p7CVY53sWwBvkpE2z0YrSZQJOAe+tgCS Jz8x6lTmodGLsaeP0ct5BgwOCw2shX1zTU9+9jI6AO/8MUbQvHC7Xvx0maeC4D3LbyvgjURtldKF b5ZI8gxESX62wDYv/z/EvB4Mp4tborW9PZV6QtZAyPbi34vsDRDCCwOtVpk4JfNqofsifyejQYy6 NUEtPGRR158JvDP4iAnT5MDTAHTAQAcMO0MyuT8V0IPKmJcirIfjWcZp4EViDmekHtJ3T+DZinSP /cLE+ARa3h4Bb+1mWAsheC2kd1OR3lmIrJ6ybQQtb7JL0wx4y2XJHcAkoh3VgLgSiZXpXQfS1EFm 1fBYpOwccIEuwiSSA2/ZmSgzZG2mxnMUqnGxlt/BvEDrOmRf3AAwVhC2LTGvp1pWb4JsrG0jXRQy oYvgtZkGQsl611Il0cEqyqzIVyZs74jGDPswceBNywdwW+86pQHC/m9qXg68ADd3Sti6CnSPuyia 9Bhakq0CaHcA54l9nOI7paVTi95xomgU33eErQ6ihxHgGpcJ3VJ1z6y0obgVFWDd7h8NW6+AFxXw yi6Yl3qnhaxV+nheqTgOaZ6H5ic2PVslzc+XamXpHuudU8qshvxdMo/W7XPw7geDAt4ENZBZCd0S dS9X32HIhg3wHmbeWcYk/09PsttYqpWud5woJvDXP6J5TQa8UQGQQ8AVzDxsBvBcA5Hk/CjzEEAG b4SnEpWOC7Lu53Vv+VIr96bMA2hMkuzr1SV415OYeReDl5c+77IflfLFUm+vRM1DIDFUR/BxUMA7 1M9DABm8E9B/ogJaewNfGnDMttS/O4l/I2letkh2G2GL4F0MvzX313OpLT/ueUqlVH3kk4NwfQMA P8G8S+jG10hC+g6pPUDXdYCnFQoFEZecZyBDipgE3huErW82PidxB244KN3kRiZyUhgYCwKOGXeC uSfwTgReeFTztIw7gI5NcO1Embhk5iXWpaYn+jAIgIOyojoI3jV0MSOh2Kb3mQ1Maldt9IPAIQnS nHFZppcpK69S3UGRxSxlFXZOe7Ti+/LsjQARlpbog1FWF7sfvtt586AAaZUuTOkJA38bAi8smp8P f1abr9S1Oi4uRklfDnzR/M3BCeA1fPP7vsRPLr5g/uaTE9HaWeVvtc//d/vPt78CDADT3mqQzF9c qgAAAABJRU5ErkJggg=="
          transform="translate(51.76 39.716)"
        />
        <g fill="#2C5ADB">
          <path d="M61.3 49.5L63 62.4s2.8 11.3 4.3 11.2c1.5-.1.9-.2.9-.2s-4.6-16-4.4-16.6c-.2-1.8-1.1-5.7-1.1-5.7l-.4-1.8s-.1-.3-.7-.3c-.4.1-.3.5-.3.5zM121 49.5l-1.7 12.9s-2.8 11.3-4.3 11.2c-1.5-.1-.9-.2-.9-.2s4.6-16 4.4-16.6c.2-1.8 1.1-5.7 1.1-5.7l.4-1.8s.1-.3.7-.3c.5.1.3.5.3.5z" />
        </g>
      </g>
      <g>
        <circle
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
          cx={135.2}
          cy={60.9}
          r={4.4}
        />
        <circle
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
          cx={144}
          cy={70.6}
          r={4.4}
        />
        <circle
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
          cx={135.2}
          cy={78.8}
          r={4.4}
        />
        <circle
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
          cx={126.5}
          cy={70}
          r={4.4}
        />
      </g>
      <g>
        <circle
          className="controller-circle"
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
          cx={144}
          cy={70.6}
          r={2.4}
        />
      </g>
      <g>
        <path
          className="controller-triangle"
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
          d="M132.7 62.9h5.1l-2.6-4.5z"
        />
      </g>
      <g>
        <g
          className="controller-x"
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
        >
          <path d="M133.1 77l4.3 4.3M133.1 81.3l4.3-4.3" />
        </g>
      </g>
      <g>
        <path
          className="controller-square"
          fill="#E2E5ED"
          stroke="#514F4F"
          strokeWidth={0.25}
          strokeMiterlimit={10}
          d="M124.5 68.1h4v4h-4z"
        />
      </g>
      <g
        fill="#2e2e2e"
        stroke="#3A3939"
        strokeWidth={0.25}
        strokeMiterlimit={10}
      >
        <circle cx={68.5} cy={91.7} r={7.8} />
        <circle cx={114.6} cy={91.7} r={7.8} />
      </g>
    </svg>
  );
}

const MemoController = React.memo(Controller);
export default MemoController;
