import * as React from "react";

function RetailHands(props) {
  return (
    <svg viewBox="0 0 93.37 130.03" {...props}>
      <g className="work-retail-hands-top">
        <path
          d="M72.31 7.32a44.58 44.58 0 0011.22 2.44s9.67-3 9.33-7c-4-.88-10-1.4-11.55-.88S67.66-3.27 49.86 5.21c-12 4.22-14.22 5.55-14.22 5.55s-4.22-.33-7 1.78-8.11 6.78-8.11 6.78a123.24 123.24 0 00-9.16 8.62h0a20.27 20.27 0 00-8.74-.5s-4.8 10.87 0 15.67a2.83 2.83 0 00.54.42 3.23 3.23 0 00.69.8c8.78 6.22 25.56 1.89 29.23-2.44 8.77.33 20.55-7.12 20.55-7.12s13.45-7.11 22.78-6.44c11.44-1.67 12.44-3.78 12.44-3.78s2.78-4.22.89-5.89a93.75 93.75 0 01-10.44.78L62.53 9.21a9.59 9.59 0 019.78-1.89z"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
        <path
          d="M72.92 16.34l6.79 1s.75 1.9-2.29 4.11-9.76 1-9.76 1"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
        <path d="M31.61 11.67c.64-.2 1.27-.41 1.89-.64" fill="#303030" />
        <path
          d="M31.75 12.15c.63-.2 1.26-.41 1.88-.64a.5.5 0 00.3-.23.5.5 0 00-.57-.73c-.62.23-1.25.44-1.88.63a.5.5 0 00.27 1z"
          fill="#efb703"
        />
        <path
          d="M49.86 5.21S62.75 3.1 71.53 8.43c10.11 2.11 10.67 3.33 10.67 3.33s-1.78 6.51-6.67 5.37-21-10.25-25.67-8.92S35.75 11 35.75 11z"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
        <path
          d="M32.2 15.65s11-4.33 16.33-2.89 20.67 7.67 20.67 7.67-.78 3.42-6.67 2.54-14-2-14.78-2.87-12 3-12 3"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
      </g>
      <g className="work-retail-hands-bottom">
        <path
          d="M21.06 122.71a44.78 44.78 0 00-11.22-2.45s-9.66 3-9.33 7c4 .89 10 1.41 11.55.89s13.65 5.15 31.45-3.33c12-4.22 14.22-5.56 14.22-5.56s4.22.34 7-1.77 8.11-6.78 8.11-6.78 4.62-3.86 9.16-8.62h0a20.19 20.19 0 008.74.51s4.8-10.87 0-15.67a3.85 3.85 0 00-.53-.43 3.46 3.46 0 00-.7-.79c-8.78-6.22-25.51-1.89-29.22 2.44-8.78-.33-20.56 7.11-20.56 7.11s-13.44 7.11-22.73 6.45c-11.44 1.66-12.44 3.78-12.44 3.78s-2.78 4.22-.89 5.88a95.33 95.33 0 0110.44-.77l16.78 10.22a9.59 9.59 0 01-9.83 1.89z"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
        <path
          d="M20.46 113.69l-6.8-1s-.75-1.91 2.29-4.12 9.76-.94 9.76-.94"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
        <path d="M61.76 118.36c-.64.19-1.26.41-1.89.64" fill="#303030" />
        <path
          d="M61.63 117.88c-.64.19-1.27.4-1.89.64a.52.52 0 00-.3.23.5.5 0 000 .38.51.51 0 00.62.35c.62-.23 1.25-.44 1.88-.64a.5.5 0 00.35-.61.51.51 0 00-.61-.35z"
          fill="#efb703"
        />
        <path
          d="M43.51 124.82s-12.89 2.11-21.67-3.22c-10.11-2.11-10.66-3.34-10.66-3.34s1.77-6.5 6.66-5.36 21 10.25 25.67 8.92S57.62 119 57.62 119z"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
        <path
          d="M61.18 114.37s-11 4.34-16.34 2.89-20.66-7.66-20.66-7.66.77-3.42 6.66-2.54 14 2 14.78 2.87 12-3 12-3"
          fill="#303030"
          stroke="#efb703"
          strokeMiterlimit={10}
        />
      </g>
      <g className="work-retail-hands-text">
        <g fill="#edc21b">
          <path d="M15.55 70.15V59.46h4.52a2.82 2.82 0 011.27.29 3.66 3.66 0 011 .77A3.5 3.5 0 0123 61.6a3.26 3.26 0 01.23 1.22 3.45 3.45 0 01-.17 1.12 3.26 3.26 0 01-.48 1 3.21 3.21 0 01-.75.72 2.67 2.67 0 01-1 .4l2.63 4.12h-1.17l-2.52-3.94h-3.16v3.94zm1.06-4.88h3.49a1.72 1.72 0 00.85-.2 2 2 0 00.66-.55 2.66 2.66 0 00.42-.78 2.82 2.82 0 00.15-.92 2.69 2.69 0 00-.17-.93 2.61 2.61 0 00-.47-.77 2.36 2.36 0 00-.69-.53 1.92 1.92 0 00-.84-.2h-3.4zM36.41 69.22v.93h-7.18V59.46h7.05v.93h-6v3.86h5.23v.89h-5.23v4.08zM52.77 59.46h.89l4.44 10.69H57l-1.4-3.34h-4.8l-1.37 3.34H48.3zM55.36 66l-2.15-5.3L51 66zM63 70.15V59.46h1.06v10.69zM70.59 70.15V59.46h1v9.76h6.18v.93z" />
        </g>
      </g>
      <g className="work-retail-hands-key">
        <path
          fill="none"
          stroke="#edc21b"
          strokeMiterlimit={10}
          strokeWidth={0.5}
          d="M42.46 68.23h3.09"
        />
        <circle
          cx={42.5}
          cy={56.08}
          r={2.68}
          fill="none"
          stroke="#edc21b"
          strokeMiterlimit={10}
          strokeWidth={0.5}
        />
        <circle cx={42.5} cy={56.08} r={0.96} fill="#edc21b" />
        <path
          fill="none"
          stroke="#edc21b"
          strokeMiterlimit={10}
          strokeWidth={0.5}
          d="M38.84 60.11h7.24M42.46 59.02v11.29M42.7 66.72h3.08"
        />
        <path
          d="M42.21 70.31a.23.23 0 00.25.24.22.22 0 00.25-.24z"
          fill="#edc21b"
        />
      </g>
    </svg>
  );
}

const MemoRetailHands = React.memo(RetailHands);
export default MemoRetailHands;
