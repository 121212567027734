import * as React from "react";

function PaletteLogo(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        d="M113.01 296.44c9.42-4.6 19.69-8.45 30.62-11.34.81-.22 1.62-.42 2.44-.62 23.38-5.84 46.21-6.38 65.88-2.51 29.72 5.87 52.2 21.83 58.3 44.9 8.06 30.44-14.75 64.22-53.69 85.02a32.654 32.654 0 00-12.03 10.99 33.06 33.06 0 00-5.29 15.4c-1.51 18.46 11.7 34.89 30.07 37.33 25.18 3.36 61.72 5.05 97.81-4.5 121.21-32.09 192.91-159.6 162.46-281.24C457.87 63.17 328.74-12.7 202.91 20.78 99.56 48.28 30.39 142.25 29.43 244.75c-.01.82.01 1.73.07 2.73 2.26 41.25 46.38 67.09 83.51 48.96z"
        fill="#637381"
        stroke="#dfe3e8"
        strokeMiterlimit={10}
      />
      <path
        d="M117.15 147.16c3.8-3.8 9.05-6.16 14.85-6.16 11.6 0 21 9.4 21 21s-9.4 21-21 21-21-9.4-21-21c0-5.8 2.35-11.04 6.15-14.84"
        fill="#eba4d8"
      />
      <circle cx={235} cy={93.5} r={21} fill="#9c6ade" />
      <circle cx={365} cy={114.5} r={21} fill="#47c1bf" />
      <circle cx={428} cy={240.56} r={21} fill="#50b83c" />
      <circle cx={386} cy={353.5} r={21} fill="#edc21b" />
      <g>
        <path
          d="M224.58 279.21l-51.92 45.84L62.5 422.32c-2.22 1.97-5.04 2.85-7.83 2.71-2.79-.14-5.55-1.32-7.67-3.51-4.23-4.37-4.39-11.22-.35-15.56l99.99-107.77 47.12-50.79 30.82 31.81z"
          fill="#dfe3e8"
          stroke="#637381"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M172.67 325.06l51.92-45.84-22.13-22.83-8.69-8.97-47.12 50.79 8.09 8.34 17.93 18.51z"
          fill="#3e4b5c"
        />
        <path
          d="M154.73 306.54l47.73-50.16-8.69-8.97-47.12 50.79 8.08 8.34z"
          fill="#243142"
        />
        <path
          d="M193.76 247.41s4.8-46.18 46.57-53.3 36-35.33 36-35.33 32.5 91.1-51.75 120.44c-30.82-29.78-30.82-31.81-30.82-31.81z"
          fill="#e3d0ff"
        />
      </g>
    </svg>
  );
}

const MemoPaletteLogo = React.memo(PaletteLogo);
export default MemoPaletteLogo;
