import * as React from "react";

function TimelineShopify(props) {
  return (
    <svg viewBox="0 0 182.8 182.8" {...props}>
      <circle
        cx={91.4}
        cy={91.4}
        r={89.9}
        fill="none"
        stroke="#edc21b"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M131.2 54.3c-.1-.5-.5-1-1.1-1-.5 0-9-.2-9-.2s-7.2-7-7.9-7.6c-.7-.6-2.1-.5-2.6-.3 0 0-1.4.4-3.6 1.1-.4-1.5-1-2.9-1.7-4.2-2.5-4.9-6.3-7.5-10.8-7.5-.3 0-.7 0-1 .1-.1-.2-.3-.3-.4-.5a9.27 9.27 0 00-7.5-3c-5.8.2-11.7 4.4-16.3 11.9-3.3 5.2-5.5 11-6.6 17-6.7 2-11.4 3.5-11.5 3.6-3.4 1.1-3.5 1.2-3.9 4.4-.3 2.4-9.2 70.9-9.2 70.9l74.2 12.8 32.1-8c.1-.2-13.1-88.9-13.2-89.5zm-27.8-6.9c-1.8.5-3.7 1.2-5.7 1.8.1-3.6-.5-7.2-1.7-10.6 4.3.8 6.4 5.8 7.4 8.8zm-9.8 3c-3.9 1.2-8.2 2.5-12.4 3.8 1.1-4.5 3.2-8.6 6.2-12.1 1.2-1.3 2.6-2.3 4.2-3.1 1.8 3.3 2.2 8.1 2 11.4zM85.8 35c1.2 0 2.4.2 3.5.8-1.7 1-3.2 2.2-4.6 3.6-3.7 4-6.5 10.2-7.6 16.1l-10.2 3.1c2-9.3 9.9-23.4 18.9-23.6z"
        fill="#95bf47"
      />
      <path
        d="M130.2 53.3c-.5 0-9-.2-9-.2s-7.2-7-7.9-7.6c-.3-.3-.6-.4-1-.5v106.5l32.2-7.9s-13.1-88.7-13.2-89.4c-.2-.5-.6-.9-1.1-.9z"
        fill="#5e8e3e"
      />
      <path
        d="M94.5 69.8l-3.7 14c-2.9-1.2-6-1.7-9.1-1.6-7.3.5-7.3 5.1-7.3 6.2.4 6.2 16.8 7.6 17.8 22.2.7 11.6-6.1 19.4-15.9 20-11.9.8-18.4-6.2-18.4-6.2l2.5-10.7s6.5 5 11.8 4.6c2.6-.1 4.6-2.3 4.5-4.8v-.1c-.5-8.2-13.9-7.6-14.8-21.1-.7-11.3 6.7-22.7 23-23.7 6.3-.4 9.6 1.2 9.6 1.2z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoTimelineShopify = React.memo(TimelineShopify);
export default MemoTimelineShopify;
