import * as React from "react";

function ProjectsTagify(props) {
  return (
    <svg viewBox="0 0 182.8 182.8" {...props}>
      <g data-name="circle">
        <circle
          cx={91.4}
          cy={91.4}
          r={89.9}
          fill="none"
          stroke="#47C1BF"
          strokeMiterlimit={10}
          strokeWidth={3}
          data-name="Layer 2"
        />
      </g>
      <path
        d="M99.8 40.38a51 51 0 1051 51 51 51 0 00-51-51zm23.4 73.59a3.17 3.17 0 01-4.37 1c-12-7.32-27.06-9-44.82-4.92a3.18 3.18 0 01-1.42-6.2c19.44-4.44 36.11-2.53 49.56 5.69a3.18 3.18 0 011.05 4.43zm6.25-13.92a4 4 0 01-5.48 1.31c-13.71-8.41-34.62-10.85-50.84-5.92a4 4 0 01-2.31-7.62c18.53-5.62 41.57-2.89 57.32 6.78a4 4 0 011.31 5.45zm.53-14.46c-16.44-9.77-43.57-10.67-59.27-5.9a4.78 4.78 0 01-2.77-9.14c18-5.47 48-4.41 66.91 6.83a4.773 4.773 0 11-4.87 8.21z"
        fill="#1ed760"
      />
      <path
        fill="#1ead4d"
        d="M16.31 111.52l20.19 20.51 25.26-24.85.71-20.9-20.91.38-25.25 24.86z"
      />
      <circle cx={54.52} cy={92.9} r={1.99} fill="#fff" />
    </svg>
  );
}

const MemoProjectsTagify = React.memo(ProjectsTagify);
export default MemoProjectsTagify;
