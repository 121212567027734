import React from "react";
import ProjectsArsenal from "../../../../assets/ProjectsArsenal";
import ArsenalAppMockup1 from "../../../../assets/projects/arsenal-app-mockup-1.png";
import ArsenalAppMockup2 from "../../../../assets/projects/arsenal-app-mockup-2.png";
import translations from "./translations/en.json";

type Props = {
  backToProjectsButton: React.ReactNode;
};

function Arsenal({ backToProjectsButton }: Props) {
  return (
    <div className="project-info">
      <ProjectsArsenal className="projects-icon" />
      <h1 className="project-info__title">
        <span className="blue-underline">
          {translations.arsenalApp.toLocaleUpperCase()}
        </span>
      </h1>
      <p
        className="project-info__demo"
        dangerouslySetInnerHTML={{
          __html: translations.codeAndDemo,
        }}
      ></p>
      <h2 className="project-info__goals">
        <span className="projects-highlight">{translations.projectGoals}</span>
      </h2>
      <ul className="project-info__goals-list">
        <li>{translations.goals[1]}</li>
        <li>{translations.goals[2]}</li>
        <li>{translations.goals[3]}</li>
        <li>{translations.goals[4]}</li>
        <li>{translations.goals[5]}</li>
        <li>{translations.goals[6]}</li>
      </ul>
      <p className="project-info__description">{translations.description}</p>
      <h2 className="project-info__mock-ups">
        <span className="projects-highlight">{translations.mockups}</span>
      </h2>
      <img
        className="project-info__image"
        src={ArsenalAppMockup1}
        alt="Arsenal App Mock Up 1"
        onClick={() => {
          window.open(ArsenalAppMockup1, "_blank");
        }}
      />
      <img
        className="project-info__image"
        src={ArsenalAppMockup2}
        alt="Arsenal App Mock Up 2"
        onClick={() => {
          window.open(ArsenalAppMockup2, "_blank");
        }}
      />
      {backToProjectsButton}
    </div>
  );
}

export default Arsenal;
