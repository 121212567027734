import * as React from "react";

function LocalizationCsvTranslations(props) {
  return (
    <svg viewBox="0 0 41.29 59.09" {...props}>
      <rect
        x={3.64}
        y={0.5}
        width={33.86}
        height={40.73}
        rx={2}
        fill="none"
        stroke="#58ba6b"
        strokeMiterlimit={10}
      />
      <text
        transform="translate(11.37 33.42)"
        fontSize={9.76}
        fill="#fcfcfc"
        fontFamily="Raleway-Regular, Raleway"
      >
        {"C"}
        <tspan x={6.7} y={0} letterSpacing="-.02em">
          {"S"}
        </tspan>
        <tspan x={12.46} y={0} letterSpacing="-.03em">
          {"V"}
        </tspan>
      </text>
      <g
        fill="#dddbdb"
        stroke="#58ba6b"
        strokeMiterlimit={10}
        strokeWidth={0.25}
      >
        <path d="M8.58 4.72h5.64v2.66H7.81V5.5a.77.77 0 01.77-.78zM14.23 4.72h6.42v2.66h-6.42z" />
        <path d="M20.64 4.72h6.42v2.66h-6.42z" />
        <path d="M27.02 4.72h5.63a.77.77 0 01.78.78v1.88h-6.41zM7.81 7.38h6.42v2.66H7.81z" />
        <path d="M14.23 7.38h6.42v2.66h-6.42z" />
        <path d="M20.64 7.38h6.42v2.66h-6.42z" />
        <path d="M27.06 7.38h6.42v2.66h-6.42zM7.81 10.04h6.42v2.66H7.81z" />
        <path d="M14.23 10.04h6.42v2.66h-6.42z" />
        <path d="M20.64 10.04h6.42v2.66h-6.42z" />
        <path d="M27.06 10.04h6.42v2.66h-6.42zM7.81 12.7h6.42v2.66H7.81z" />
        <path d="M14.23 12.7h6.42v2.66h-6.42z" />
        <path d="M20.64 12.7h6.42v2.66h-6.42z" />
        <path d="M27.06 12.7h6.42v2.66h-6.42zM7.81 15.37h6.41v2.66H8.58a.77.77 0 01-.77-.78zM14.23 15.37h6.42v2.66h-6.42z" />
        <path d="M20.64 15.37h6.42v2.66h-6.42z" />
        <path d="M27.02 15.37h6.41v1.88a.77.77 0 01-.78.78h-5.63z" />
      </g>
      <g className="localization-csv-translations-arrow">
        <circle cx={20.64} cy={42.18} r={6.1} fill="#479e56" />
        <path
          fill="#e8e8e8"
          d="M21.41 43.56v-4.52h-1.54v4.52h-1.12l1.89 2.96 1.9-2.96h-1.13z"
        />
      </g>
      <g fill="#fcfcfc" className="localization-csv-translations-export">
        <path d="M5.23 58.36v.68H.02v-7.8h5.12v.68H.76v2.86h3.82v.65H.76v3zM6.63 51.24l2.56 3.41 2.56-3.41h.84l-3 4 2.91 3.84h-.84l-2.47-3.3-2.47 3.26h-.87l2.91-3.84-3-4zM13.62 59.04v-7.8h3.23a1.93 1.93 0 01.93.22 2.5 2.5 0 01.73.56 2.83 2.83 0 01.51.76 2.32 2.32 0 01.18.89 2.69 2.69 0 01-.17.93 2.44 2.44 0 01-.46.78 2.26 2.26 0 01-.71.55 2 2 0 01-.91.21h-2.56v2.88zm.77-3.56h2.49a1.26 1.26 0 00.62-.15 1.53 1.53 0 00.48-.39 2 2 0 00.31-.57 2.2 2.2 0 00.11-.68 1.8 1.8 0 00-.13-.69 1.68 1.68 0 00-.34-.56 1.55 1.55 0 00-.5-.38 1.47 1.47 0 00-.61-.14h-2.43zM23.59 59.09a3.31 3.31 0 01-1.48-.31 3.63 3.63 0 01-1.16-.89 4 4 0 01-.75-1.27 4.09 4.09 0 01-.26-1.46 4 4 0 01.28-1.49 4 4 0 01.8-1.29 3.89 3.89 0 011.17-.87 3.3 3.3 0 011.44-.32 3.2 3.2 0 011.49.34 3.68 3.68 0 011.15.91 4.14 4.14 0 01.75 1.27 4.21 4.21 0 01.26 1.44 4 4 0 01-.26 1.49 4 4 0 01-.77 1.26 3.72 3.72 0 01-1.23.88 3.21 3.21 0 01-1.43.31zm-2.89-4a3.86 3.86 0 00.21 1.22 3.3 3.3 0 00.6 1.05 2.9 2.9 0 00.91.72 2.69 2.69 0 002.37 0 2.88 2.88 0 00.91-.75 3.66 3.66 0 00.58-1 3.76 3.76 0 00.2-1.19 3.6 3.6 0 00-.21-1.22 3.54 3.54 0 00-.6-1 3 3 0 00-.91-.72 2.52 2.52 0 00-1.17-.27 2.46 2.46 0 00-1.19.28 2.75 2.75 0 00-.91.74 3.43 3.43 0 00-.58 1 3.51 3.51 0 00-.21 1.19zM28.7 59.04v-7.8h3.32a1.93 1.93 0 01.93.22 2.37 2.37 0 01.73.56 2.42 2.42 0 01.48.78 2.51 2.51 0 01.17.89 2.47 2.47 0 01-.13.82 2.42 2.42 0 01-.35.7 2.09 2.09 0 01-.54.53 1.94 1.94 0 01-.71.29l1.91 3h-.87l-1.84-2.88h-2.3v2.88zm.77-3.56h2.55a1.32 1.32 0 00.62-.15 1.5 1.5 0 00.47-.4 1.76 1.76 0 00.31-.57 2.09 2.09 0 00.11-.67 1.89 1.89 0 00-.12-.67 2 2 0 00-.34-.57 1.7 1.7 0 00-.51-.38 1.31 1.31 0 00-.61-.15h-2.48zM41.28 51.92h-2.77v7.12h-.77v-7.12h-2.78v-.68h6.32z" />
      </g>
      <g fill="#fcfcfc" className="localization-csv-translations-import">
        <path d="M.63 59.04v-7.8h.77v7.8zM10.26 59.04v-6.4l-2.84 5h-.4l-2.85-5v6.4h-.81v-7.8h.79l3 5.34 3.06-5.34h.81v7.8zM13.02 59.04v-7.8h3.22a1.93 1.93 0 01.93.22 2.37 2.37 0 01.73.56 2.64 2.64 0 01.49.78 2.51 2.51 0 01.17.89 2.68 2.68 0 01-.16.93 2.64 2.64 0 01-.46.78 2.38 2.38 0 01-.71.55 2 2 0 01-.92.21h-2.56v2.88zm.76-3.56h2.5a1.32 1.32 0 00.62-.15 1.79 1.79 0 00.48-.39 2.07 2.07 0 00.41-1.25 2 2 0 00-.12-.69 2.09 2.09 0 00-.34-.56 1.58 1.58 0 00-.51-.38 1.43 1.43 0 00-.61-.14h-2.46zM22.96 59.09a3.36 3.36 0 01-1.49-.31 3.63 3.63 0 01-1.16-.89 4.14 4.14 0 01-.75-1.27 4.32 4.32 0 01-.26-1.46 4 4 0 01.28-1.49 4.19 4.19 0 01.77-1.27 3.89 3.89 0 011.17-.87 3.35 3.35 0 011.5-.34 3.23 3.23 0 011.49.34 3.8 3.8 0 011.15.91 4.12 4.12 0 01.74 1.27 4.11 4.11 0 010 2.93 3.85 3.85 0 01-.77 1.26 3.8 3.8 0 01-1.15.87 3.24 3.24 0 01-1.52.32zm-2.89-4a3.6 3.6 0 00.21 1.22 3.28 3.28 0 00.59 1.05 3 3 0 00.91.72 2.53 2.53 0 001.18.27 2.46 2.46 0 001.19-.28 2.92 2.92 0 00.92-.75 3.63 3.63 0 00.57-1 3.5 3.5 0 00.2-1.19 3.6 3.6 0 00-.21-1.22 3.15 3.15 0 00-.6-1 2.9 2.9 0 00-.91-.72 2.67 2.67 0 00-2.36 0 2.85 2.85 0 00-.91.74 3.66 3.66 0 00-.58 1 3.76 3.76 0 00-.2 1.21zM28.02 59.04v-7.8h3.3a2 2 0 01.93.22 2.5 2.5 0 01.73.56 2.58 2.58 0 01.47.78 2.31 2.31 0 01.17.89 2.76 2.76 0 01-.12.82 2.7 2.7 0 01-.35.7 2.13 2.13 0 01-.55.53 1.94 1.94 0 01-.71.29l1.91 3h-.78l-1.83-2.88h-2.36v2.88zm.77-3.56h2.55a1.29 1.29 0 00.62-.15 1.43 1.43 0 00.48-.4 2.24 2.24 0 00.31-.57 2.09 2.09 0 00.1-.67 1.89 1.89 0 00-.12-.67 2 2 0 00-.34-.57 1.48 1.48 0 00-.51-.38 1.28 1.28 0 00-.61-.15h-2.44zM40.65 51.92h-2.78v7.12h-.77v-7.12h-2.78v-.68h6.33z" />
      </g>
    </svg>
  );
}

const MemoLocalizationCsvTranslations = React.memo(LocalizationCsvTranslations);
export default MemoLocalizationCsvTranslations;
