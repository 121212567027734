import * as React from "react";

function PosGraphql(props) {
  return (
    <svg viewBox="0 0 258.35 87.11" {...props}>
      <path
        d="M113.53 83.88v-81S114.16 0 108.81 0H4.74C-.62 0 .02 2.88.02 2.88v81.35s-.64 2.88 4.72 2.88h104.07c5.35 0 4.72-2.88 4.72-2.88zm-7.35 1H7.46c-5.08.05-4.44-2.77-4.44-2.77V4.97s-.64-2.79 4.44-2.79h98.72c5.08 0 4.44 2.79 4.44 2.79v77.14s.64 2.82-4.44 2.82z"
        fill="#5e5d5e"
      />
      <path
        d="M78.05 79.26H36.32s-3 0-3 1 2.45.87 2.45.87H78.5s2.45.09 2.45-.87-2.9-1-2.9-1z"
        fill="#fcfcfc"
      />
      <g fill="#e535ab">
        <path d="M191.024 63.246l32.555-56.387 2.918 1.685-32.555 56.387z" />
        <path d="M190.36 58.74h65.12v3.37h-65.12z" />
        <path d="M191.648 60.638l1.685-2.919 32.571 18.805-1.685 2.919zM219.94 11.623l1.684-2.919 32.571 18.805-1.685 2.919z" />
        <path d="M191.65 27.498l32.57-18.805 1.686 2.919-32.571 18.805z" />
        <path d="M219.363 8.548l2.919-1.685 32.555 56.387-2.919 1.685zM192.94 25.26h3.37v37.61h-3.37z" />
        <path d="M249.54 25.26h3.37v37.61h-3.37z" />
        <path d="M222.171 75.454L250.5 59.099l1.475 2.555-28.328 16.355z" />
        <path d="M257.38 63.96a7.1 7.1 0 11-2.6-9.69 7.1 7.1 0 012.6 9.69M200.75 31.25a7.1 7.1 0 11-2.6-9.69 7.1 7.1 0 012.6 9.69M188.47 63.96a7.1 7.1 0 119.7 2.6 7.11 7.11 0 01-9.7-2.6M245.11 31.25a7.1 7.1 0 119.7 2.6 7.11 7.11 0 01-9.7-2.6M222.93 83.87a7.1 7.1 0 117.09-7.1 7.1 7.1 0 01-7.09 7.1M222.93 18.44a7.1 7.1 0 117.09-7.09 7.09 7.09 0 01-7.09 7.09" />
      </g>
      <g className="work-pos-graphql-dots" fill="#e535ab">
        <circle
          className="work-pos-graphql-dots__1"
          cx={128.32}
          cy={45.31}
          r={3.39}
        />
        <circle
          className="work-pos-graphql-dots__2"
          cx={143.06}
          cy={45.31}
          r={3.39}
        />
        <circle
          className="work-pos-graphql-dots__3"
          cx={157.8}
          cy={45.71}
          r={3.39}
        />
        <circle
          className="work-pos-graphql-dots__4"
          cx={174.35}
          cy={45.71}
          r={3.39}
        />
      </g>
      <g>
        <path
          d="M41.24 42.4a6.54 6.54 0 10-6.53-6.53 6.53 6.53 0 006.53 6.53zm9.81 13.82a1.63 1.63 0 001.63-1.63 11.44 11.44 0 00-22.88 0 1.64 1.64 0 001.64 1.63z"
          fill="#5e5d5e"
        />
        <ellipse
          cx={41.24}
          cy={43.83}
          rx={18.47}
          ry={18.28}
          fill="none"
          stroke="#5e5d5e"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <g>
          <path
            d="M60 35.26s-1.42-18.53 10.59-18.53 10.59 18.53 10.59 18.53"
            fill="none"
            stroke="#5e5d5e"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <path d="M91.51 33.37v23.74a5 5 0 01-5 5H49.68" fill="#dbad1d" />
          <path d="M49.68 62.11V38.37a5 5 0 015-5h36.83" fill="#edc21b" />
          <path
            d="M76.23 43.87l-.12-.34a5.92 5.92 0 10-9.23 6.77"
            fill="#5e5d5e"
          />
          <path
            d="M66.88 50.3l.18.14a6.25 6.25 0 00.82.51c.14 1.9.69 4.61 2.71 4.61s2.58-2.71 2.72-4.61a5.9 5.9 0 003.2-5.26 6.13 6.13 0 00-.28-1.82"
            fill="#414042"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoPosGraphql = React.memo(PosGraphql);
export default MemoPosGraphql;
