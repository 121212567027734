import React from "react";
import translations from "./translations/en.json";

import DragonIpad from "../../assets/DragonIpad";
import AdobeMonitor from "../../assets/AdobeMonitor";
import "./Design.scss";
import SlideTransition from "../SlideTransition/SlideTransition";

function Design() {
  const content = (
    <div className="design-container">
      <DragonIpad className="design-dragon" />
      <div className="design-container-content">
        <h1 className="design-container-header">
          <span className="purple-underline">{translations.design}</span>
        </h1>
        <p>{translations.designDescription}</p>
      </div>
      <div className="svg-container-content">
        <h1 className="svg-container-header">
          <span className="purple-underline">{translations.svg}</span>
        </h1>
        <p>{translations.svgDescription}</p>
      </div>
      <AdobeMonitor className="design-adobe-monitor" />
      <div className="digital-container-content">
        <h1 className="digital-container-header">
          <span className="purple-underline">{translations.digitalArt}</span>
        </h1>
        <p>{translations.digitalArtDescription}</p>
      </div>
    </div>
  );

  return <SlideTransition>{content}</SlideTransition>;
}

export default Design;
