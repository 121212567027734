import React from "react";
import ProjectsWeather from "../../../../assets/ProjectsWeather";

import WeatherApp1 from "../../../../assets/projects/weather-app-1.png";
import WeatherApp2 from "../../../../assets/projects/weather-app-2.png";
import WeatherApp3 from "../../../../assets/projects/weather-app-3.png";
import WeatherAppMockup1 from "../../../../assets/projects/weather-app-mockup-1.png";
import WeatherAppMockup2 from "../../../../assets/projects/weather-app-mockup-2.png";

import translations from "./translations/en.json";

type Props = {
  backToProjectsButton: React.ReactNode;
};

function Weather({ backToProjectsButton }: Props) {
  return (
    <div className="project-info">
      <ProjectsWeather className="projects-icon" />
      <h1 className="project-info__title">
        <span className="blue-underline">
          {translations.weatherApp.toLocaleUpperCase()}
        </span>
      </h1>
      <p
        className="project-info__demo"
        dangerouslySetInnerHTML={{
          __html: translations.codeAndDemo,
        }}
      ></p>
      <h2 className="project-info__goals">
        <span className="projects-highlight">{translations.projectGoals}</span>
      </h2>
      <ul className="project-info__goals-list">
        <li>{translations.goals[1]}</li>
        <li>{translations.goals[2]}</li>
        <li>{translations.goals[3]}</li>
        <li>{translations.goals[4]}</li>
      </ul>
      <p className="project-info__description">{translations.description}</p>
      <h2 className="project-info__mock-ups">
        <span className="projects-highlight">{translations.mockups}</span>
      </h2>
      <img
        className="project-info__image"
        src={WeatherAppMockup1}
        alt="Weather App Mock Up 1"
        onClick={() => {
          window.open(WeatherAppMockup1, "_blank");
        }}
      />
      <img
        className="project-info__image"
        src={WeatherAppMockup2}
        alt="Weather App Mock Up 2"
        onClick={() => {
          window.open(WeatherAppMockup2, "_blank");
        }}
      />
      <h2 className="project-info__screenshots">
        <span className="projects-highlight">{translations.screenshots}</span>
      </h2>
      <img
        className="project-info__image"
        src={WeatherApp1}
        alt="Weather App 1"
        onClick={() => {
          window.open(WeatherApp1, "_blank");
        }}
      />
      <img
        className="project-info__image"
        src={WeatherApp2}
        alt="Weater App 2"
        onClick={() => {
          window.open(WeatherApp2, "_blank");
        }}
      />
      <img
        className="project-info__image"
        src={WeatherApp3}
        alt="Weater App 3"
        onClick={() => {
          window.open(WeatherApp3, "_blank");
        }}
      />
      {backToProjectsButton}
    </div>
  );
}

export default Weather;
