import * as React from "react";

function Avatar(props) {
  return (
    <svg
      viewBox="0 0 220 220"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
      {...props}
    >
      <path
        d="M88.85 132.63l-2.68 23.27s13.61 7.2 24.81 4.43v-27.7H88.85z"
        fill="#b6866a"
        fillRule="nonzero"
      />
      <path
        d="M132.66 132.63l3.17 23.26s-16.33 7.2-29.77 4.43v-27.69h26.6z"
        fill="#b6866a"
        fillRule="nonzero"
      />
      <path
        d="M137.808 150.575l-.298-.095H84.32c-50.97 16.23-53.15 31.85-53.15 31.85 26.76 37.24 79.78 35.47 79.78 35.47s53 1.77 79.78-35.47c-.03 0-2.241-15.558-52.922-31.755z"
        fill="#dc84ac"
        fillRule="nonzero"
      />
      <path
        d="M104.144 216.252c-.148 6.086-73.071-18.449-72.974-34.031.097-15.646 53.509-31.113 53.509-31.113l.014.041c1.244 3.473 8.811 6.327 19.451 9.805v55.298z"
        fill="#ad6183"
        stroke="#ad6183"
        strokeWidth={1.38}
        transform="matrix(1 0 0 1.01874 .016 -3.46)"
      />
      <path
        d="M104.144 216.252c0 10.098-73.599-26.214-72.974-34.031 1.247-15.598 53.509-31.113 53.509-31.113l.014.041c1.244 3.473 8.811 6.327 19.451 9.805v55.298z"
        fill="#ad6183"
        stroke="#ad6183"
        strokeWidth={1.38}
        transform="matrix(-1 0 0 1.01874 221.884 -3.46)"
      />
      <path
        d="M104.16 160.643v56.416M117.741 160.643v56.416"
        fill="none"
        stroke="#965271"
        strokeWidth={1.39}
      />
      <path
        d="M104.16 160.643s-18.633-7.474-19.4-10.163M117.74 160.643s18.633-7.474 19.4-10.163"
        fill="none"
        stroke="#965271"
        strokeWidth={1.35}
      />
      <path
        d="M146.36 102.71s-4.54-28.27 8-32.68c0 0 3.59 2.91-.78 17.88-3.6 6.74-4.41 12.89-7.22 14.8z"
        fill="#b6866a"
        fillRule="nonzero"
      />
      <path
        d="M109.69 26.41s49.77-1.67 40.77 43.67c-1.88 28.44-3.22 28.55-3 33.11.22 4.56.23 12.33-1.33 16.55-1.56 4.22-26.67 29.34-35.44 26.11"
        fill="#a6795e"
        fillRule="nonzero"
      />
      <path
        d="M111.68 26.41s-50.72-1.67-41.51 43.67c1.93 28.44 3.29 28.55 3.06 33.11-.23 4.56-.23 12.33 1.36 16.55 1.59 4.22 27.17 29.34 36.12 26.11"
        fill="#b6866a"
        fillRule="nonzero"
      />
      <ellipse
        cx={81.11}
        cy={113.38}
        rx={0.36}
        ry={0.38}
        fill="#2f302f"
        transform="translate(0 -2.68)"
      />
      <ellipse cx={77.64} cy={111.46} rx={0.36} ry={0.38} fill="#2f302f" />
      <path
        d="M74.45 102.71s4.53-28.27-8-32.68c0 0-3.59 2.91.78 17.88 3.6 6.74 4.37 12.89 7.22 14.8z"
        fill="#a6795e"
        fillRule="nonzero"
      />
      <g>
        <path
          d="M101.07 69.09l-1.9 1.11H85.09l-2.62 1.22s-1.55-1.22 4.89-4.66h11.69a6.48 6.48 0 012.02 2.33zM119.73 69.09l1.91 1.11h14.07l2.62 1.22s1.55-1.22-4.89-4.66h-11.68a6.573 6.573 0 00-2.03 2.33zM86.83 85.95a7.332 7.332 0 013.75-8.53c7-1.56 7.75 4.54 7.75 4.54l-11.5 3.99z"
          fill="#2f302f"
          fillRule="nonzero"
        />
        <path
          d="M98.09 80.99a7.322 7.322 0 01-3.74 8.53c-7.05 1.56-7.75-4.55-7.75-4.55l11.49-3.98z"
          fill="#2f302f"
          fillRule="nonzero"
        />
        <g>
          <path
            d="M135.95 86.05a7.333 7.333 0 00-3.78-8.53c-7-1.56-7.75 4.54-7.75 4.54l11.53 3.99z"
            fill="#2f302f"
            fillRule="nonzero"
          />
          <path
            d="M124.69 81.09a7.332 7.332 0 003.75 8.53c7 1.56 7.74-4.55 7.74-4.55l-11.49-3.98z"
            fill="#2f302f"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g fill="#2f302f">
        <ellipse cx={142.18} cy={114.03} rx={0.35} ry={0.38} />
        <ellipse cx={139.5} cy={113.28} rx={0.35} ry={0.38} />
        <ellipse cx={137.44} cy={114.95} rx={0.35} ry={0.38} />
        <ellipse cx={135.34} cy={117.65} rx={0.35} ry={0.38} />
        <ellipse cx={128.42} cy={120.57} rx={0.35} ry={0.38} />
        <ellipse cx={125.47} cy={121.33} rx={0.35} ry={0.38} />
        <ellipse cx={122.3} cy={122.73} rx={0.35} ry={0.38} />
        <ellipse cx={119.69} cy={123.1} rx={0.35} ry={0.38} />
        <ellipse cx={115.37} cy={123.86} rx={0.35} ry={0.38} />
        <ellipse cx={112.7} cy={123.86} rx={0.35} ry={0.38} />
        <ellipse cx={142.88} cy={111.37} rx={0.35} ry={0.38} />
        <ellipse cx={144.12} cy={107.78} rx={0.35} ry={0.38} />
        <ellipse cx={145.14} cy={103.83} rx={0.35} ry={0.38} />
        <ellipse cx={146.36} cy={102.71} rx={0.35} ry={0.38} />
        <ellipse cx={144.47} cy={105.65} rx={0.35} ry={0.38} />
        <ellipse cx={145.31} cy={110.05} rx={0.35} ry={0.38} />
        <ellipse cx={144.12} cy={119.64} rx={0.35} ry={0.38} />
        <ellipse cx={138.1} cy={128.13} rx={0.35} ry={0.38} />
        <ellipse cx={138.8} cy={119.26} rx={0.35} ry={0.38} />
        <ellipse cx={140.46} cy={125.12} rx={0.35} ry={0.38} />
        <ellipse cx={134.16} cy={122.08} rx={0.35} ry={0.38} />
        <ellipse cx={129.88} cy={130.5} rx={0.35} ry={0.38} />
        <ellipse cx={122.11} cy={129.18} rx={0.35} ry={0.38} />
        <ellipse cx={115.37} cy={130.88} rx={0.35} ry={0.38} />
        <ellipse cx={126.03} cy={133.85} rx={0.35} ry={0.38} />
        <ellipse cx={128.16} cy={125.5} rx={0.35} ry={0.38} />
        <ellipse cx={116.92} cy={127.03} rx={0.35} ry={0.38} />
        <ellipse cx={114.67} cy={139.35} rx={0.35} ry={0.38} />
        <ellipse cx={115.02} cy={143.77} rx={0.35} ry={0.38} />
        <ellipse cx={120.59} cy={140.1} rx={0.35} ry={0.38} />
        <ellipse cx={125.68} cy={138.27} rx={0.35} ry={0.38} />
        <ellipse cx={129.88} cy={135.28} rx={0.35} ry={0.38} />
        <ellipse cx={133.81} cy={130.88} rx={0.35} ry={0.38} />
        <ellipse cx={132.69} cy={127.03} rx={0.35} ry={0.38} />
        <ellipse cx={132.02} cy={123.86} rx={0.35} ry={0.38} />
        <ellipse cx={126.03} cy={125.5} rx={0.35} ry={0.38} />
        <ellipse cx={118.03} cy={137.08} rx={0.35} ry={0.38} />
        <ellipse cx={111.37} cy={135.66} rx={0.35} ry={0.38} />
        <ellipse cx={142.18} cy={107.06} rx={0.35} ry={0.38} />
        <ellipse cx={141.44} cy={110.05} rx={0.35} ry={0.38} />
        <ellipse cx={137.79} cy={113.28} rx={0.35} ry={0.38} />
        <ellipse cx={134.51} cy={115.71} rx={0.35} ry={0.38} />
        <ellipse cx={132.34} cy={117.47} rx={0.35} ry={0.38} />
        <ellipse cx={143.42} cy={116.46} rx={0.35} ry={0.38} />
        <ellipse cx={146.01} cy={113.66} rx={0.35} ry={0.38} />
        <ellipse cx={139.15} cy={116.46} rx={0.35} ry={0.38} />
        <ellipse cx={141.09} cy={121.71} rx={0.35} ry={0.38} />
        <ellipse cx={136.74} cy={123.1} rx={0.35} ry={0.38} />
        <ellipse cx={132.34} cy={120.57} rx={0.35} ry={0.38} />
        <ellipse cx={136.04} cy={119.26} rx={0.35} ry={0.38} />
        <ellipse cx={139.15} cy={123.1} rx={0.35} ry={0.38} />
        <ellipse cx={141.09} cy={118.41} rx={0.35} ry={0.38} />
        <ellipse cx={140.74} cy={115.71} rx={0.35} ry={0.38} />
        <ellipse cx={146.01} cy={108.16} rx={0.35} ry={0.38} />
        <ellipse cx={126.69} cy={127.79} rx={0.35} ry={0.38} />
        <ellipse cx={122.92} cy={125.12} rx={0.35} ry={0.38} />
        <ellipse cx={119.07} cy={133.85} rx={0.35} ry={0.38} />
        <ellipse cx={121.95} cy={135.66} rx={0.35} ry={0.38} />
        <ellipse cx={120.24} cy={128.54} rx={0.35} ry={0.38} />
        <ellipse cx={113.48} cy={128.92} rx={0.35} ry={0.38} />
        <ellipse cx={112.7} cy={136.41} rx={0.35} ry={0.38} />
        <ellipse cx={112} cy={142.22} rx={0.35} ry={0.38} />
        <ellipse cx={117.36} cy={142.97} rx={0.35} ry={0.38} />
        <ellipse cx={113.13} cy={132.77} rx={0.35} ry={0.38} />
        <ellipse cx={123.61} cy={131.25} rx={0.35} ry={0.38} />
        <ellipse cx={135.24} cy={131.25} rx={0.35} ry={0.38} />
        <ellipse cx={134.89} cy={126.63} rx={0.35} ry={0.38} />
        <ellipse cx={126.34} cy={123.1} rx={0.35} ry={0.38} />
        <ellipse cx={129.18} cy={122.08} rx={0.35} ry={0.38} />
        <ellipse cx={127.46} cy={132.77} rx={0.35} ry={0.38} />
        <ellipse cx={118.72} cy={125.87} rx={0.35} ry={0.38} />
        <ellipse cx={116.92} cy={132.01} rx={0.35} ry={0.38} />
        <ellipse cx={129.71} cy={127.19} rx={0.52} ry={0.57} />
        <ellipse cx={133.28} cy={129.75} rx={0.52} ry={0.57} />
        <ellipse cx={145.49} cy={117.65} rx={0.52} ry={0.57} />
        <ellipse cx={143.95} cy={112.31} rx={0.52} ry={0.57} />
        <ellipse cx={126.52} cy={130.88} rx={0.52} ry={0.57} />
        <ellipse cx={122.39} cy={138.84} rx={0.52} ry={0.57} />
        <ellipse cx={126.52} cy={136.6} rx={0.52} ry={0.57} />
        <ellipse cx={129.96} cy={133.71} rx={0.52} ry={0.57} />
        <ellipse cx={119.71} cy={132.01} rx={0.52} ry={0.57} />
        <ellipse cx={113.58} cy={127.57} rx={0.52} ry={0.57} />
        <ellipse cx={114.84} cy={135.47} rx={0.52} ry={0.57} />
        <ellipse cx={137.23} cy={120.76} rx={0.52} ry={0.57} />
        <ellipse cx={138.1} cy={124.93} rx={0.17} ry={0.19} />
        <ellipse cx={134.33} cy={124.93} rx={0.17} ry={0.19} />
        <ellipse cx={123.79} cy={127.6} rx={0.17} ry={0.19} />
        <ellipse cx={124.95} cy={129.94} rx={0.17} ry={0.19} />
        <ellipse cx={124.05} cy={133.66} rx={0.17} ry={0.19} />
        <ellipse cx={120.93} cy={133.34} rx={0.17} ry={0.19} />
        <ellipse cx={117.86} cy={129.75} rx={0.17} ry={0.19} />
        <ellipse cx={111.83} cy={131.44} rx={0.17} ry={0.19} />
        <ellipse cx={111.65} cy={126.06} rx={0.17} ry={0.19} />
        <ellipse cx={116.4} cy={124.42} rx={0.17} ry={0.19} />
        <ellipse cx={117.68} cy={123.48} rx={0.17} ry={0.19} />
        <ellipse cx={129.53} cy={119.45} rx={0.17} ry={0.19} />
        <ellipse cx={130.55} cy={118.6} rx={0.17} ry={0.19} />
        <ellipse cx={137.26} cy={118.03} rx={0.17} ry={0.19} />
        <ellipse cx={142} cy={124.61} rx={0.17} ry={0.19} />
        <ellipse cx={133.11} cy={132.76} rx={0.17} ry={0.19} />
        <ellipse cx={131.5} cy={132.2} rx={0.17} ry={0.19} />
        <ellipse cx={135.86} cy={128.73} rx={0.17} ry={0.19} />
        <ellipse cx={130.55} cy={129.48} rx={0.17} ry={0.19} />
        <ellipse cx={118.89} cy={138.46} rx={0.17} ry={0.19} />
        <ellipse cx={116.05} cy={141.24} rx={0.17} ry={0.19} />
        <ellipse cx={112.55} cy={144.78} rx={0.17} ry={0.19} />
        <ellipse cx={111.2} cy={139.59} rx={0.17} ry={0.19} />
        <ellipse cx={114.14} cy={137.35} rx={0.17} ry={0.19} />
        <ellipse cx={113.31} cy={143.16} rx={0.17} ry={0.19} />
        <ellipse cx={113.31} cy={140.29} rx={0.17} ry={0.19} />
        <ellipse cx={124.05} cy={137.26} rx={0.17} ry={0.19} />
        <ellipse cx={121.94} cy={133.52} rx={0.17} ry={0.19} />
        <ellipse cx={120.41} cy={130.92} rx={0.17} ry={0.19} />
        <ellipse cx={121.14} cy={126.25} rx={0.17} ry={0.19} />
        <ellipse cx={121.14} cy={124.61} rx={0.17} ry={0.19} />
        <ellipse cx={124.3} cy={123.29} rx={0.17} ry={0.19} />
        <ellipse cx={133.98} cy={119.27} rx={0.17} ry={0.19} />
        <ellipse cx={135.69} cy={121.52} rx={0.17} ry={0.19} />
        <ellipse cx={131.81} cy={122.65} rx={0.17} ry={0.19} />
        <ellipse cx={130.84} cy={120.95} rx={0.17} ry={0.19} />
        <ellipse cx={142.35} cy={121.14} rx={0.17} ry={0.19} />
        <ellipse cx={144.62} cy={115.14} rx={0.17} ry={0.19} />
        <ellipse cx={78.36} cy={114.13} rx={0.36} ry={0.38} />
        <ellipse cx={81.11} cy={113.38} rx={0.36} ry={0.38} />
        <ellipse cx={83.23} cy={115.05} rx={0.36} ry={0.38} />
        <ellipse cx={85.39} cy={117.75} rx={0.36} ry={0.38} />
        <ellipse cx={92.51} cy={120.67} rx={0.36} ry={0.38} />
        <ellipse cx={95.54} cy={121.43} rx={0.36} ry={0.38} />
        <ellipse cx={98.81} cy={122.82} rx={0.36} ry={0.38} />
        <ellipse cx={101.49} cy={123.2} rx={0.36} ry={0.38} />
        <ellipse cx={105.93} cy={123.95} rx={0.36} ry={0.38} />
        <ellipse cx={108.67} cy={123.95} rx={0.36} ry={0.38} />
        <ellipse cx={77.64} cy={111.46} rx={0.36} ry={0.38} />
        <ellipse cx={76.36} cy={107.88} rx={0.36} ry={0.38} />
        <ellipse cx={76.36} cy={105.27} rx={0.36} ry={0.38} />
        <ellipse cx={74.42} cy={104.21} rx={0.36} ry={0.38} />
        <ellipse cx={75.14} cy={110.15} rx={0.36} ry={0.38} />
        <ellipse cx={76.36} cy={119.73} rx={0.36} ry={0.38} />
        <ellipse cx={83.23} cy={127.13} rx={0.36} ry={0.38} />
        <ellipse cx={81.83} cy={119.36} rx={0.36} ry={0.38} />
        <ellipse cx={80.13} cy={125.22} rx={0.36} ry={0.38} />
        <ellipse cx={86.61} cy={122.18} rx={0.36} ry={0.38} />
        <ellipse cx={91.01} cy={130.6} rx={0.36} ry={0.38} />
        <ellipse cx={99} cy={129.28} rx={0.36} ry={0.38} />
        <ellipse cx={105.93} cy={130.97} rx={0.36} ry={0.38} />
        <ellipse cx={94.97} cy={133.94} rx={0.36} ry={0.38} />
        <ellipse cx={92.78} cy={125.59} rx={0.36} ry={0.38} />
        <ellipse cx={104.33} cy={127.13} rx={0.36} ry={0.38} />
        <ellipse cx={106.65} cy={139.45} rx={0.36} ry={0.38} />
        <ellipse cx={106.29} cy={142.87} rx={0.36} ry={0.38} />
        <ellipse cx={100.93} cy={137.08} rx={0.36} ry={0.38} />
        <ellipse cx={95.33} cy={138.37} rx={0.36} ry={0.38} />
        <ellipse cx={91.01} cy={135.38} rx={0.36} ry={0.38} />
        <ellipse cx={86.97} cy={130.97} rx={0.36} ry={0.38} />
        <ellipse cx={88.12} cy={127.13} rx={0.36} ry={0.38} />
        <ellipse cx={88.81} cy={123.95} rx={0.36} ry={0.38} />
        <ellipse cx={94.97} cy={125.59} rx={0.36} ry={0.38} />
        <ellipse cx={103.19} cy={137.17} rx={0.36} ry={0.38} />
        <ellipse cx={110.04} cy={135.76} rx={0.36} ry={0.38} />
        <ellipse cx={78.36} cy={107.16} rx={0.36} ry={0.38} />
        <ellipse cx={79.12} cy={110.15} rx={0.36} ry={0.38} />
        <ellipse cx={82.87} cy={113.38} rx={0.36} ry={0.38} />
        <ellipse cx={86.25} cy={115.81} rx={0.36} ry={0.38} />
        <ellipse cx={88.48} cy={117.56} rx={0.36} ry={0.38} />
        <ellipse cx={77.08} cy={116.56} rx={0.36} ry={0.38} />
        <ellipse cx={74.42} cy={113.75} rx={0.36} ry={0.38} />
        <ellipse cx={81.47} cy={116.56} rx={0.36} ry={0.38} />
        <ellipse cx={78.72} cy={123.1} rx={0.36} ry={0.38} />
        <ellipse cx={83.95} cy={123.2} rx={0.36} ry={0.38} />
        <ellipse cx={88.48} cy={120.67} rx={0.36} ry={0.38} />
        <ellipse cx={84.67} cy={119.36} rx={0.36} ry={0.38} />
        <ellipse cx={81.47} cy={123.2} rx={0.36} ry={0.38} />
        <ellipse cx={79.48} cy={118.51} rx={0.36} ry={0.38} />
        <ellipse cx={79.84} cy={115.81} rx={0.36} ry={0.38} />
        <ellipse cx={74.42} cy={108.26} rx={0.36} ry={0.38} />
        <ellipse cx={94.29} cy={127.88} rx={0.36} ry={0.38} />
        <ellipse cx={98.17} cy={125.22} rx={0.36} ry={0.38} />
        <ellipse cx={102.13} cy={133.94} rx={0.36} ry={0.38} />
        <ellipse cx={99.17} cy={135.76} rx={0.36} ry={0.38} />
        <ellipse cx={100.93} cy={128.64} rx={0.36} ry={0.38} />
        <ellipse cx={107.87} cy={129.01} rx={0.36} ry={0.38} />
        <ellipse cx={108.67} cy={136.51} rx={0.36} ry={0.38} />
        <ellipse cx={109.39} cy={142.32} rx={0.36} ry={0.38} />
        <ellipse cx={102.89} cy={141.15} rx={0.36} ry={0.38} />
        <ellipse cx={108.23} cy={132.87} rx={0.36} ry={0.38} />
        <ellipse cx={97.46} cy={131.35} rx={0.36} ry={0.38} />
        <ellipse cx={85.85} cy={126.72} rx={0.36} ry={0.38} />
        <ellipse cx={94.65} cy={123.2} rx={0.36} ry={0.38} />
        <ellipse cx={91.73} cy={122.18} rx={0.36} ry={0.38} />
        <ellipse cx={93.5} cy={132.87} rx={0.36} ry={0.38} />
        <ellipse cx={102.49} cy={125.97} rx={0.36} ry={0.38} />
        <ellipse cx={104.69} cy={133.62} rx={0.36} ry={0.38} />
        <ellipse cx={91.19} cy={127.29} rx={0.54} ry={0.57} />
        <ellipse cx={87.51} cy={129.85} rx={0.54} ry={0.57} />
        <ellipse cx={74.96} cy={117.75} rx={0.54} ry={0.57} />
        <ellipse cx={76.54} cy={112.41} rx={0.54} ry={0.57} />
        <ellipse cx={94.47} cy={130.98} rx={0.54} ry={0.57} />
        <ellipse cx={98.71} cy={138.93} rx={0.54} ry={0.57} />
        <ellipse cx={94.47} cy={136.7} rx={0.54} ry={0.57} />
        <ellipse cx={90.93} cy={133.81} rx={0.54} ry={0.57} />
        <ellipse cx={101.47} cy={132.11} rx={0.54} ry={0.57} />
        <ellipse cx={107.77} cy={127.67} rx={0.54} ry={0.57} />
        <ellipse cx={106.47} cy={135.57} rx={0.54} ry={0.57} />
        <ellipse cx={82.37} cy={121.24} rx={0.54} ry={0.57} />
        <ellipse cx={82.55} cy={125.03} rx={0.18} ry={0.19} />
        <ellipse cx={86.43} cy={125.03} rx={0.18} ry={0.19} />
        <ellipse cx={97.28} cy={127.69} rx={0.18} ry={0.19} />
        <ellipse cx={96.08} cy={130.04} rx={0.18} ry={0.19} />
        <ellipse cx={97} cy={133.76} rx={0.18} ry={0.19} />
        <ellipse cx={100.22} cy={133.43} rx={0.18} ry={0.19} />
        <ellipse cx={103.37} cy={129.85} rx={0.18} ry={0.19} />
        <ellipse cx={109.57} cy={131.54} rx={0.18} ry={0.19} />
        <ellipse cx={109.75} cy={126.16} rx={0.18} ry={0.19} />
        <ellipse cx={104.87} cy={124.52} rx={0.18} ry={0.19} />
        <ellipse cx={103.55} cy={123.58} rx={0.18} ry={0.19} />
        <ellipse cx={91.37} cy={119.55} rx={0.18} ry={0.19} />
        <ellipse cx={90.32} cy={118.69} rx={0.18} ry={0.19} />
        <ellipse cx={83.41} cy={118.13} rx={0.18} ry={0.19} />
        <ellipse cx={87.69} cy={132.86} rx={0.18} ry={0.19} />
        <ellipse cx={89.35} cy={132.29} rx={0.18} ry={0.19} />
        <ellipse cx={84.85} cy={128.82} rx={0.18} ry={0.19} />
        <ellipse cx={90.32} cy={129.58} rx={0.18} ry={0.19} />
        <ellipse cx={102.31} cy={138.56} rx={0.18} ry={0.19} />
        <ellipse cx={105.23} cy={141.33} rx={0.18} ry={0.19} />
        <ellipse cx={109.86} cy={144.88} rx={0.18} ry={0.19} />
        <ellipse cx={110.22} cy={139.69} rx={0.18} ry={0.19} />
        <ellipse cx={107.19} cy={137.45} rx={0.18} ry={0.19} />
        <ellipse cx={108.05} cy={143.26} rx={0.18} ry={0.19} />
        <ellipse cx={108.05} cy={140.39} rx={0.18} ry={0.19} />
        <ellipse cx={97} cy={137.36} rx={0.18} ry={0.19} />
        <ellipse cx={99.18} cy={133.62} rx={0.18} ry={0.19} />
        <ellipse cx={100.75} cy={131.02} rx={0.18} ry={0.19} />
        <ellipse cx={100} cy={126.35} rx={0.18} ry={0.19} />
        <ellipse cx={100} cy={124.71} rx={0.18} ry={0.19} />
        <ellipse cx={96.75} cy={123.39} rx={0.18} ry={0.19} />
        <ellipse cx={86.79} cy={119.36} rx={0.18} ry={0.19} />
        <ellipse cx={85.03} cy={121.61} rx={0.18} ry={0.19} />
        <ellipse cx={89.02} cy={122.74} rx={0.18} ry={0.19} />
        <ellipse cx={90.02} cy={121.05} rx={0.18} ry={0.19} />
        <ellipse cx={78.18} cy={121.24} rx={0.18} ry={0.19} />
        <ellipse cx={75.85} cy={115.24} rx={0.18} ry={0.19} />
        <ellipse cx={146.71} cy={105.65} rx={0.35} ry={0.38} />
        <ellipse cx={118.38} cy={140.2} rx={0.52} ry={0.57} />
        <ellipse cx={120.41} cy={142.45} rx={0.17} ry={0.19} />
      </g>
      <g>
        <circle
          cx={109.89}
          cy={109.89}
          r={108.39}
          fill="none"
          stroke="url(#prefix___Radial1)"
          strokeWidth={3}
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
        />
      </g>
      <g>
        <path
          d="M80.503 87.037s-.12-6.137.848-7.227c.97-1.09 3.351-4.724 5.895-4.885 2.544-.162 9.61-.848 10.86-.04 1.253.807 5.37 3.068 5.29 6.338-.08 3.27-.484 6.218-.484 6.218s-2.302 5.531-5.491 7.146c-3.19 1.615-9.448 1.414-11.184.202-1.737-1.211-5.855-6.258-5.734-7.752zM142.064 87.037s.121-6.137-.848-7.227c-.969-1.09-3.351-4.724-5.895-4.885-2.543-.162-9.609-.848-10.86-.04-1.252.807-5.37 3.068-5.29 6.338.08 3.27.485 6.218.485 6.218s2.301 5.531 5.49 7.146c3.19 1.615 9.449 1.414 11.185.202 1.736-1.211 5.854-6.258 5.733-7.752z"
          fill="none"
          stroke="#ebd0be"
          strokeWidth={1.3938708}
        />
        <path
          d="M119.17 83.081s-5.608-2.09-7.951-2.149"
          fill="none"
          stroke="#ebd0be"
          strokeWidth={1.41}
          transform="matrix(.97723 0 0 1 2.533 0)"
        />
        <path
          d="M119.17 83.081s-5.608-2.09-7.951-2.149"
          fill="none"
          stroke="#ebd0be"
          strokeWidth={1.42}
          transform="matrix(-.96688 0 0 1 218.755 0)"
        />
        <path
          d="M80.498 80.651s-8.293-8.001-10.453-7.903"
          fill="none"
          stroke="#ebd0be"
          strokeWidth={1.42}
          transform="matrix(.9379 0 0 1.02284 5 -1.842)"
        />
        <path
          d="M80.498 80.651s-8.293-8.001-10.453-7.903"
          fill="none"
          stroke="#ebd0be"
          strokeWidth={1.53}
          transform="matrix(-.79338 0 0 1.01515 205.505 -1.222)"
        />
      </g>
      <g>
        <path
          d="M104.107 103.04s-.425 1.713 3.754 1.016c1.308-.218 1.55.606 2.059.775.508.17.58.025-.848-1.38-1.429-1.405-3.633-1.938-4.965-.412z"
          fill="#a6795e"
        />
        <path
          d="M117.793 103.04s.425 1.713-3.754 1.016c-1.308-.218-1.55.606-2.058.775-.509.17-.582.025.847-1.38 1.43-1.405 3.633-1.938 4.965-.412z"
          fill="#b6866a"
        />
      </g>
      <defs>
        <radialGradient
          id="prefix___Radial1"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.89 109.89) scale(109.89)"
        >
          <stop offset={0} stopColor="#b781b8" />
          <stop offset={1} stopColor="#e3a5ca" />
        </radialGradient>
      </defs>
    </svg>
  );
}

const MemoAvatar = React.memo(Avatar);
export default MemoAvatar;
