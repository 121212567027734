import * as React from "react";

function ProjectsErModel(props) {
  return (
    <svg viewBox="0 0 182.8 182.8" {...props}>
      <g data-name="circle">
        <circle
          cx={91.4}
          cy={91.4}
          r={89.9}
          fill="none"
          stroke="#47C1BF"
          strokeMiterlimit={10}
          strokeWidth={3}
          data-name="Layer 2"
        />
      </g>
      <path fill="#3a94c1" d="M25.51 88.09h37.28v8.5H25.51z" />
      <path
        fill="none"
        stroke="#47c1bf"
        strokeMiterlimit={10}
        d="M25.18 95.87v-8.08h38v38h-38V95.87M25.18 96.59h38"
      />
      <path fill="#3a94c1" d="M73.22 88.09h37.28v8.5H73.22z" />
      <path
        fill="none"
        stroke="#47c1bf"
        strokeMiterlimit={10}
        d="M72.88 95.87v-8.08h38v38h-38V95.87M72.88 96.59h38"
      />
      <path fill="#3a94c1" d="M119.96 88.09h37.28v8.5h-37.28z" />
      <path
        fill="none"
        stroke="#47c1bf"
        strokeMiterlimit={10}
        d="M119.62 95.87v-8.08h38v38h-38V95.87M119.62 96.59h38"
      />
      <path fill="#3a94c1" d="M73.56 42.23h37.28v8.5H73.56z" />
      <path
        fill="none"
        stroke="#47c1bf"
        strokeMiterlimit={10}
        d="M73.22 50.01v-8.08h38v38h-38V50.01M73.22 50.73h38"
      />
      <path
        fill="none"
        stroke="#47c1bf"
        strokeMiterlimit={10}
        d="M42.4 87.79V50.73h31.16"
      />
      <path
        fill="#fff"
        stroke="#47c1bf"
        strokeMiterlimit={10}
        d="M91.88 79.93v7.86"
      />
      <path
        fill="none"
        stroke="#47c1bf"
        strokeMiterlimit={10}
        d="M94.62 125.79v15.08h44v-15.08"
      />
      <path
        fill="#47c1bf"
        d="M69.45 48.59v4.28l4.11-2.14-4.11-2.14zM94 83.98h-4.28l2.14 4.11L94 83.98zM136.46 129.51h4.28l-2.14-4.11-2.14 4.11z"
      />
    </svg>
  );
}

const MemoProjectsErModel = React.memo(ProjectsErModel);
export default MemoProjectsErModel;
