import React from "react";
import ProjectsWatslit from "../../../../assets/ProjectsWatslit";
import WatsLitFlow from "../../../../assets/projects/wats-lit-flow.png";

import translations from "./translations/en.json";

type Props = {
  backToProjectsButton: React.ReactNode;
};

function WatsLit({ backToProjectsButton }: Props) {
  return (
    <div className="project-info">
      <ProjectsWatslit className="projects-icon" />
      <h1 className="project-info__title">
        <span className="blue-underline">
          {translations.watsLit.toLocaleUpperCase()}
        </span>
      </h1>
      <p
        className="project-info__demo"
        dangerouslySetInnerHTML={{
          __html: translations.codeAndDemo,
        }}
      ></p>
      <h2 className="project-info__goals">
        <span className="projects-highlight">{translations.projectGoals}</span>
      </h2>
      <ul className="project-info__goals-list">
        <li>{translations.goals[1]}</li>
        <li>{translations.goals[2]}</li>
        <li>{translations.goals[3]}</li>
        <li>{translations.goals[4]}</li>
        <li>{translations.goals[5]}</li>
      </ul>
      <h2 className="project-info__decode-hackathon-title">
        <span className="projects-highlight">
          {translations.decodeHackathon}
        </span>
      </h2>
      <p className="project-info__decode-hackathon-description">
        {translations.decodeHackathonDescription}
      </p>
      <p className="project-info__planning-description">
        {translations.planningDescription}
      </p>
      <h2 className="project-info__mock-ups">
        <span className="projects-highlight">{translations.mockups}</span>
      </h2>
      <img
        className="project-info__image"
        src={WatsLitFlow}
        alt="WatsLit Mockup"
        onClick={() => {
          window.open(WatsLitFlow, "_blank");
        }}
      />
      {backToProjectsButton}
    </div>
  );
}

export default WatsLit;
