import React from "react";
import "./Retail.scss";
import RetailHands from "../../../../assets/RetailHands";
import Pickup from "../../../../assets/Pickup";
import PosGrapgql from "../../../../assets/PosGraphql";
import translations from "./translations/en.json";

function Retail() {
  return (
    <>
      <RetailHands className="work-retail-hands" />
      <div className="flex-break"></div>
      <div className="pickup">
        <h1 className="pickup__header">
          <span className="gold-underline">{translations.pickup}</span>
        </h1>
        <p
          className="pickup__content"
          dangerouslySetInnerHTML={{ __html: translations.pickupContent }}
        ></p>
      </div>
      <Pickup className="work-pickup" />
      <div className="flex-break"></div>
      <div className="building-apis">
        <h1 className="building-apis__header">
          <span className="gold-underline">{translations.buildingAPIs}</span>
        </h1>
        <p
          className="building-apis__content"
          dangerouslySetInnerHTML={{ __html: translations.buildingAPIsContent }}
        ></p>
      </div>
      <PosGrapgql className="work-pos-graphql" />
    </>
  );
}

export default Retail;
