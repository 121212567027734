import * as React from "react";

function Arsenal(props) {
  return (
    <svg viewBox="0 0 182.7 182.7" {...props}>
      <circle
        fill="none"
        stroke="#EBA4D8"
        strokeWidth={3}
        strokeMiterlimit={10}
        cx={91.4}
        cy={91.4}
        r={89.9}
      />
      <g>
        <path
          fill="#FFF"
          stroke="#303030"
          strokeWidth={0.25}
          d="M81 83.7l-5.2 11.5 5.4 12.6 12 10.7c-12.6 0-22.9-10.2-22.9-22.9 0-12.6 10.2-22.9 22.9-22.9L81 83.7zm-5.4 11.9"
        />
        <g fill="#FFF" stroke="#303030" strokeWidth={0.25}>
          <path d="M124.7 75.5c0-1.3-1-2.3-2.3-2.3-1.3 0-2.3 1-2.3 2.3H37s-7.6-.2-7.6-1.7v11.6c0-1.6 7.6-1.7 7.6-1.7h83.1c0 1.3 1 2.3 2.3 2.3 1.3 0 2.3-1 2.3-2.3v-8.2zM127.9 81.4c0 1.3-1.4 2.3-3.2 2.3v-8.2c1.7 0 3.2 1 3.2 2.3v3.6z" />
          <path d="M114.1 72.4c2.3 0 4.1 1.4 4.1 3.1H52.5l.6-3.1h3.7v-1.2h1.9l2.5 1.2h52.9z" />
          <path d="M108 72.4h4.4v-2.5h-1.2zM96.8 72.4h-9.9l6.3-7.4s1.5-1.9 3.9-2.1c2.4-.2 5.4-.4 5.4-.4s2.2-.4-.2 2.7c-2.4 3.2-5.5 7.2-5.5 7.2z" />
        </g>
        <g stroke="#303030" strokeWidth={0.25}>
          <path
            fill="#FFF"
            d="M158.4 104.1l-42.9-10.7-11.5-9.7H55.6c0 3.4 2.1 5.5 5.5 5.5h18.6L91.3 96l61.7 15.5c1.2.3 2.4.4 3.7.4 6.8 0 12.5-4 13.7-9.2l-12 1.4z"
          />
          <path
            fill="none"
            d="M131.2 100l-.8 3.1-7.3-1.9.8-3.1zM143.6 103.1l-.8 3.1-7.3-1.9.8-3.1zM156 106.2l-.8 3.1-7.3-1.9.8-3.1z"
          />
          <path
            fill="#FFF"
            d="M133.5 96.1l-.4 1.7-8.6-2.2.5-1.6zM157.9 102.2l-.4 1.7-8.5-2.1.4-1.7z"
          />
        </g>
        <g fill="#FFF">
          <path d="M27 112.3c0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5c-4.7-.1-8.5 3.8-8.5 8.5zm1.2 0c0-4 3.3-7.3 7.3-7.3s7.3 3.3 7.3 7.3-3.3 7.3-7.3 7.3c-4-.1-7.3-3.3-7.3-7.3zM36.6 96.1c0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5-8.5 3.8-8.5 8.5zm1.3 0c0-4 3.3-7.3 7.3-7.3s7.3 3.3 7.3 7.3-3.3 7.3-7.3 7.3c-4.1 0-7.3-3.2-7.3-7.3zM46.2 112.3c0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5-3.8-8.5-8.5-8.5c-4.6-.1-8.5 3.8-8.5 8.5zm1.3 0c0-4 3.3-7.3 7.3-7.3s7.3 3.3 7.3 7.3-3.3 7.3-7.3 7.3c-4.1-.1-7.3-3.3-7.3-7.3z" />
          <path d="M44.1 96.8l1.1-3.6 1.1 3.6h-2.2zm.1-6.1l-3 9.9H43l.7-2.2h3.2l.7 2.2h1.8l-3-9.9h-2.2zM58.3 114.6c-.7 1.6-1.9 2.6-3.4 2.6-2.2 0-3.9-2.3-3.9-5.2 0-2.9 1.7-5.2 3.9-5.2 1.4 0 2.7 1 3.4 2.6l-1.5.5c-.4-.8-1.1-1.4-1.8-1.4-1.2 0-2.2 1.6-2.2 3.5s1 3.5 2.2 3.5c.7 0 1.4-.6 1.8-1.4l1.5.5zM32.4 117.1v-9.9h6.2v1.8h-4.4v2.3h3.2v1.8h-3.2v4z" />
        </g>
      </g>
      <g>
        <g
          fill="#FFF"
          stroke="#303030"
          strokeWidth={0.25}
          className="arsenal-wheel"
        >
          <path d="M91.8 91.7l-12.6-3.3-1 3.6 12.4 3.3-12.1 6.3 1.7 3.3 12-6.3-5.9 13.2 3.4 1.5 5.8-12.8 1 14.3 3.7-.3-.8-11.9 9.2 9.4 2.6-2.6-9.5-9.7 12.9 3.3.9-3.6-12.7-3.2 12.7-4.8-1.3-3.5-12.5 4.8 6.9-11.9-3.2-1.8-6.7 11.4-.2-13.6h-3.7l.2 12.9-8.7-10.6-2.8 2.4z" />
          <path d="M119.7 96.2c0 12.6-10.2 22.9-22.9 22.9S74 108.9 74 96.2c0-12.6 10.2-22.9 22.9-22.9s22.8 10.3 22.8 22.9zM96.9 78.7c-9.7 0-17.6 7.9-17.6 17.6 0 9.7 7.9 17.6 17.6 17.6 9.7 0 17.6-7.9 17.6-17.6 0-9.8-7.9-17.6-17.6-17.6z" />
          <path d="M102.2 96.2c0 2.9-2.4 5.3-5.3 5.3s-5.3-2.4-5.3-5.3 2.4-5.3 5.3-5.3c2.9.1 5.3 2.4 5.3 5.3z" />
        </g>
      </g>
    </svg>
  );
}

const MemoArsenal = React.memo(Arsenal);
export default MemoArsenal;
