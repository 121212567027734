import React from "react";
import ProjectsErModel from "../../../../assets/ProjectsErModel";
import ERModeler1 from "../../../../assets/projects/er-modeler1.png";
import ERModeler2 from "../../../../assets/projects/er-modeler2.png";
import ERModeler3 from "../../../../assets/projects/er-modeler3.png";

import translations from "./translations/en.json";

type Props = {
  backToProjectsButton: React.ReactNode;
};

function ERModeler({ backToProjectsButton }: Props) {
  return (
    <div className="project-info">
      <ProjectsErModel className="projects-icon" />
      <h1 className="project-info__title">
        <span className="blue-underline">
          {translations.ERModel.toLocaleUpperCase()}
        </span>
      </h1>
      <p
        className="project-info__demo"
        dangerouslySetInnerHTML={{
          __html: translations.ERModlerProject.codeAndDemo,
        }}
      ></p>
      <h2 className="project-info__goals">
        <span className="projects-highlight">{translations.projectGoals}</span>
      </h2>
      <ul className="project-info__goals-list">
        <li>{translations.ERModlerProject.goals[1]}</li>
        <li>{translations.ERModlerProject.goals[2]}</li>
      </ul>
      <p className="project-info__reflections1">
        {translations.ERModlerProject.reflections1}
      </p>
      <p className="project-info__reflections2">
        {translations.ERModlerProject.reflections2}
      </p>
      <p className="project-info__reflections3">
        {translations.ERModlerProject.reflections3}
      </p>
      <h2 className="project-info__mock-ups">
        <span className="projects-highlight">{translations.screenshots}</span>
      </h2>
      <img
        className="project-info__image"
        src={ERModeler1}
        alt="ER Modeler Screenshots"
        onClick={() => {
          window.open(ERModeler1, "_blank");
        }}
      />
      <img
        className="project-info__image"
        src={ERModeler2}
        alt="ER Modeler Screenshots"
        onClick={() => {
          window.open(ERModeler2, "_blank");
        }}
      />
      <img
        className="project-info__image"
        src={ERModeler3}
        alt="ER Modeler Screenshots"
        onClick={() => {
          window.open(ERModeler3, "_blank");
        }}
      />
      {backToProjectsButton}
    </div>
  );
}

export default ERModeler;
