import * as React from "react";

function SocialLinkedin(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 96.92 96.92" {...props}>
      <path
        d="M48.46 0a48.46 48.46 0 1048.46 48.46A48.46 48.46 0 0048.46 0zm-14.6 75.36h-11.8v-38h11.8zm-5.91-43.18a6.87 6.87 0 116.84-6.89 6.88 6.88 0 01-6.84 6.89zm50.07 43.17H66.24V56.86c0-4.41-.09-10.06-6.14-10.06s-7.07 4.79-7.07 9.74v18.81H41.25v-38h11.31v5.18h.17a12.39 12.39 0 0111.16-6.07c11.93 0 14.13 7.82 14.13 18z"
        fill="#FFFFFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

const MemoSocialLinkedin = React.memo(SocialLinkedin);
export default MemoSocialLinkedin;
