import * as React from "react";

function DragonIpad(props) {
  return (
    <svg viewBox="0 0 200.63 235.13" {...props}>
      <path
        d="M96.93 173L74.7 199.62a4.11 4.11 0 001.11 6.38l47.5 27.88a6.12 6.12 0 007.52-1.31l52.8-63.2a3.85 3.85 0 00-1.24-6.13l-49.64-25.62a6.53 6.53 0 00-7.65 1.55l-22.2 26.63"
        fill="none"
        stroke="#5e5d5e"
        strokeMiterlimit={10}
      />
      <path
        d="M163.81 179.47l-14.5 17.59-.26.31L134.54 215s-2.1 2.54-1.17 3.31 2.49-1.45 2.49-1.45l14.82-18 .32-.38 14.83-18s1.79-2 .94-2.72-2.96 1.71-2.96 1.71z"
        fill="#fcfcfc"
      />
      <g fill="#fcfcfc">
        <path d="M195.59 148.81a2.94 2.94 0 00-3-1l-16.67 8.48-19 9.7 1.82 4.7 16.66-8.49 19-9.69s2.25-2.26 1.19-3.7zM155.82 166.24l1.89 4.7-5.75-.08 3.78-4.47" />
      </g>
      <path
        className="design-dragon-wings"
        d="M107.33 75.88s26.17-6.47 19.5-37.47c28-25.56 71.67-36.89 71.67-36.89s-46.57 37.33-41.62 52 28.51-8 28.51-8 1.67 26-18.39 34.22 6.39 27.56 6.39 27.56-33.12 4-37.12-13.56-37.15-3.4-37.15-3.4"
        fill="none"
        stroke="#9c6ade"
        strokeMiterlimit={10}
      />
      <path
        className="design-dragon-wings"
        d="M95.46 75.81S82.5 72.18 78.4 64M74.14 44.61s.46-5.4.94-6.2S32.94 4.3 2.05.74C38.57 24.52 45 48.38 45 48.38s.61 23.25-28.84-3.38c1.23 25.56 15.1 33 15.1 33s17.09 5.68 7.89 18.81c-7.1 7.79-12.65 11-12.65 11h8M68 89s5.59-6.88 20-3.14"
        fill="none"
        stroke="#9c6ade"
        strokeMiterlimit={10}
      />
      <g fill="none" stroke="#eba4d8" strokeMiterlimit={10} strokeWidth={0.25}>
        <path
          className="design-dragon-spikes"
          d="M115.65 162.9s5.47-8.73 10.35-10.06c-2.38 7.08-5.18 8.62-5.18 8.62s7-6.71 9.72-6.41c0 5.7-5.75 7.16-5.75 7.16s6.79-3.25 9.33-.54c-4.14 4.22-5.55 3-5.55 3M92.84 106.35s11.61-13 17.84-13.76c-1.5 9.94-5.84 11.2-5.84 11.2s9.23-8.54 16.06-6.92c.43 7.26-7.12 7.49-7.12 7.49s15.26-3.36 16.67-.69c-4.87 7-10.75 4.55-10.75 4.55M103.4 55.09s10.78-.45 12.89 3.44c-6.45 3.45-11.25 0-11.25 0s8.47 3.34 10 5.45c-3.42 3.44-8.13 0-8.13 0s7.9 4.55 7.35 7.66c-5.11-.66-6.45-2.77-6.45-2.77"
        />
      </g>
      <g fill="none" strokeMiterlimit={10}>
        <path
          className="design-dragon-body"
          d="M135.14 184s1.36-26-19.09-21.55c-21.33 8.52-37.11 2.08-36.89-3.19s3.67-6.59 37.34-16.15c26.66-21-1.56-38.66-1.56-38.66s-14.44-3.6-25.89 3.55-34.33 20.22-39.89 3.56c-1-13.67 30.23-9.93 30.23-9.93s42-11.91 23.77-46.74c12.39-4.22 8.84-4.44 8.84-4.44l-14.78-2.5s6.83-5.34 5-10.17c-3 4.29-14.22 6.85-14.22 6.85s3-3.61 1.33-7.17c-2.61 3.45-8.06 6.67-8.83 7a64.21 64.21 0 01-6.39 1l-4.5 2.72-10.22.39s-4.28 2.11-4.73 4 5.39 4.28 5.39 4.28L59.83 54l1.33 2.44.45-2.5 2 2 .39-1.39 1 1.28 1.66-1.5 3.28.17-2.72 2.61v2.28l-2.61-.23.06 1.34h-1.61L63 61l-3.16-2.5s-1.12 4.61.66 6.61a15.15 15.15 0 003.5 3.34l-.16-4.28 4.33 2.5-.67-3.33s16.77-.77 18.5 2.51c1-2.17-4.5-7.85-4.5-7.85s24.33 8.46 9.72 25c-6.78 9.06-28.17 5.5-28.17 5.5s-20.44-1.61-28.89 21.17c-3.33 27.11 34 26.67 44.67 19.78s28.33-19.56 33.78-13.67c10.22 7.44 6.22 19-4 21s-34.22 4.78-36.11 12.78c-4.34 7.11 1.66 16.72 1.66 16.72s14.23 13.83 42.67 1.5c12.34-.71 12.28 5.29 18.31 16.22z"
          stroke="#9c6ade"
        />
        <path
          className="design-dragon-eye"
          d="M73.61 49.27l2-1.75s4-.64 5.33.36-3 3.25-3 3.25-5.17-.06-4.33-1.86z"
          stroke="#9c6ade"
          strokeWidth={0.5}
        />
        <path
          className="design-dragon-pupil"
          d="M77.23 50.8s-1.53-2.42-.57-3.17 1.34 2.18.57 3.17z"
          stroke="#eba4d8"
          strokeWidth={0.25}
        />
      </g>
    </svg>
  );
}

const MemoDragonIpad = React.memo(DragonIpad);
export default MemoDragonIpad;
