import * as React from "react";

function ProjectsTetris(props) {
  return (
    <svg viewBox="0 0 182.8 182.8" {...props}>
      <g data-name="circle">
        <circle
          cx={91.4}
          cy={91.4}
          r={89.9}
          fill="none"
          stroke="#47C1BF"
          strokeMiterlimit={10}
          strokeWidth={3}
          data-name="Layer 2"
        />
      </g>
      <g fill="#ce5ad1" stroke="#000" strokeMiterlimit={10}>
        <path d="M73.53 55.4h35.88v35.88H73.53zM37.51 55.4h35.88v35.88H37.51zM109.41 55.4h35.88v35.88h-35.88zM73.53 91.52h35.88v35.88H73.53z" />
      </g>
      <g fill="#e47fea">
        <path d="M41.54 58.98h27.83v27.83H41.54zM77.49 58.98h27.83v27.83H77.49zM113.44 58.98h27.83v27.83h-27.83zM77.49 95.55h27.83v27.83H77.49z" />
      </g>
    </svg>
  );
}

const MemoProjectsTetris = React.memo(ProjectsTetris);
export default MemoProjectsTetris;
