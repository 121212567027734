import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Paths, isActivePath } from "../../utils/history";

import NcLogo from "../../assets/NcLogo";
import ShopifyGlyph from "../../assets/ShopifyGlyph";
import CodeMajor from "../../assets/CodeMajor";
import PaletteLogo from "../../assets/PaletteLogo";
import Avatar from "./components/Avatar/Avatar";

import "./Nav.scss";

const ENTER_KEY = 13;

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateToPath = useCallback(
    (path: string) => {
      navigate(path, {
        state: { resetState: true },
      });
    },
    [navigate],
  );

  const onKeyDownHandler = (e, path) => {
    if (e.keyCode === ENTER_KEY) {
      navigateToPath(path);
    }
  };

  return (
    <div className="header-container">
      <div className="nav">
        <div
          className={`nav-item pink ${
            isActivePath(location.pathname, Paths.Me) ? "active" : ""
          }`}
          onClick={() => {
            navigateToPath(Paths.Me);
          }}
          onKeyDown={(e) => {
            onKeyDownHandler(e, Paths.Me);
          }}
          aria-label="Navigate to me page"
          role="navigation"
          tabIndex={0}
        >
          <NcLogo className="nav-item__me" alt="me page nav" />
        </div>
        <Avatar />
        <div
          className={`nav-item gold ${
            isActivePath(location.pathname, Paths.Work) ? "active" : ""
          }`}
          onClick={() => {
            navigateToPath(Paths.Work);
          }}
          onKeyDown={(e) => {
            onKeyDownHandler(e, Paths.Work);
          }}
          aria-label="Navigate to work page"
          role="navigation"
          tabIndex={0}
        >
          <ShopifyGlyph className="nav-item__work" alt="work page nav" />
        </div>
        <br />
        <div
          className={`nav-item blue ${
            isActivePath(location.pathname, Paths.Projects) ? "active" : ""
          }`}
          onClick={() => {
            navigateToPath(Paths.Projects);
          }}
          onKeyDown={(e) => {
            onKeyDownHandler(e, Paths.Projects);
          }}
          aria-label="Navigate to projects page"
          role="navigation"
          tabIndex={0}
        >
          <CodeMajor className="nav-item__projects" alt="projects page nav" />
        </div>
        <div
          className={`nav-item purple ${
            isActivePath(location.pathname, Paths.Design) ? "active" : ""
          }`}
          onClick={() => {
            navigateToPath(Paths.Design);
          }}
          onKeyDown={(e) => {
            onKeyDownHandler(e, Paths.Design);
          }}
          aria-label="Navigate to design page"
          role="navigation"
          tabIndex={0}
        >
          <PaletteLogo className="nav-item__design" alt="design page nav" />
        </div>
      </div>
    </div>
  );
}

export default Nav;
