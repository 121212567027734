import React from "react";
import "./Localization.scss";
import translations from "./translations/en.json";
import LocalizationGlobe from "../../../../assets/LocalizationGlobe";
import LocalizationCsvTranslations from "../../../../assets/LocalizationCsvTranslations";
import AbstractProductCAN from "../../../../assets/AbstractProductCAN";
import AbstractProductUSA from "../../../../assets/AbstractProductUSA";

function Localization() {
  return (
    <div className="localization-wrapper">
      <div className="localization-animation">
        <div className="localization-globe">
          <LocalizationGlobe />
        </div>
        <h2 className="localization-animation-text">
          {translations.localization}
        </h2>
      </div>
      <div className="life-at-shopify">
        <h1 className="life-at-shopify__header">
          <span className="gold-underline">{translations.lifeAtShopify}</span>
        </h1>
        <p className="life-at-shopify__content">
          {translations.lifeAtShopifyContent}
        </p>
      </div>
      <div className="localization-content-by-market">
        <h1>
          <span className="gold-underline">
            {translations.contentByMarketHeader.toUpperCase()}
          </span>
        </h1>
        <p
          className="life-at-shopify__content"
          dangerouslySetInnerHTML={{
            __html: translations.contentByMarketDescription,
          }}
        ></p>
        <div className="localization-content-by-market-svg-wrapper">
          <div className="localization-content-by-market-CAN">
            <AbstractProductCAN />
          </div>
          <div className="localization-content-by-market-USA">
            <AbstractProductUSA />
          </div>
        </div>
      </div>
      <div className="localization-csv">
        <h1>
          <span className="gold-underline">
            {translations.csvTranslationsHeader.toUpperCase()}
          </span>
        </h1>
        <p>{translations.csvTranslationsDescription}</p>
        <div className="localization-csv-translations">
          <LocalizationCsvTranslations />
        </div>
      </div>
    </div>
  );
}

export default Localization;
