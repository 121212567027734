import React from "react";

import { Paths } from "../../../../utils/history";
import AvatarHome from "../../../../assets/Avatar";
import AvatarWork from "../../../../assets/AvatarWork";
import AvatarProjects from "../../../../assets/AvatarProjects";
import AvatarDesign from "../../../../assets/AvatarDesign";

import "./Avatar.scss";
import { useLocation } from "react-router-dom";

function Avatar() {
  const location = useLocation();
  switch (location.pathname) {
    case Paths.Work:
      return <AvatarWork className="avatar" />;
    case Paths.Projects:
      return <AvatarProjects className="avatar" />;
    case Paths.Design:
      return <AvatarDesign className="avatar" />;
    default:
      return <AvatarHome className="avatar" />;
  }
}

export default Avatar;
