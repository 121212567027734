import * as React from "react";

function ProjectsWatslit(props) {
  return (
    <svg viewBox="0 0 182.8 182.8" {...props}>
      <g data-name="circle">
        <circle
          cx={91.4}
          cy={91.4}
          r={89.9}
          fill="none"
          stroke="#47C1BF"
          strokeMiterlimit={10}
          strokeWidth={3}
          data-name="Layer 2"
        />
      </g>
      <path
        d="M49.63 141.29c.32.58 30.76 35.18 80.12 1.65-1.84-2.76-.36-3.31 9.21-20.62s-3.68-38.87-3.68-38.87-4 7.19-8.84 5.53-1.66-19-1.66-19-.73-27.05-38.86-44.73c4.42 24.5-3.5 30.57-3.5 30.57s-4.37.23-5.53-1.1-12.7 24.33-10.13 34.44c-.18 11.42-10.86 5.71-10.86 5.71s-5.71-8.1-3-12.89-3.32 1.29-3.32 1.29-11 13.08-9 26 4.27 22.99 9.05 32.02z"
        fill="#fcd251"
      />
      <text
        transform="translate(60.07 141.45)"
        fontSize={44}
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={3}
        fontFamily="Raleway-Regular, Raleway"
      >
        {"LI"}
        <tspan x={36.56} y={0} letterSpacing="-.03em">
          {"T"}
        </tspan>
      </text>
    </svg>
  );
}

const MemoProjectsWatslit = React.memo(ProjectsWatslit);
export default MemoProjectsWatslit;
