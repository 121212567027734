import React from "react";
import ProjectsTetris from "../../../../assets/ProjectsTetris";
import Tetris1 from "../../../../assets/projects/tetris1.png";
import Tetris2 from "../../../../assets/projects/tetris2.png";
import Tetris3 from "../../../../assets/projects/tetris3.png";

import translations from "./translations/en.json";

type Props = {
  backToProjectsButton: React.ReactNode;
};

function Tetris({ backToProjectsButton }: Props) {
  return (
    <div className="project-info">
      <ProjectsTetris className="projects-icon" />
      <h1 className="project-info__title">
        <span className="blue-underline">
          {translations.tetris.toLocaleUpperCase()}
        </span>
      </h1>
      <p
        className="project-info__demo"
        dangerouslySetInnerHTML={{
          __html: translations.codeAndDemo,
        }}
      ></p>
      <h2 className="project-info__goals">
        <span className="projects-highlight">{translations.projectGoals}</span>
      </h2>
      <ul className="project-info__goals-list">
        <li>{translations.goals[1]}</li>
        <li>{translations.goals[2]}</li>
        <li>{translations.goals[3]}</li>
      </ul>
      <p className="project-info__reflections1">{translations.reflections1}</p>
      <h2 className="project-info__mock-ups">
        <span className="projects-highlight">{translations.screenshots}</span>
      </h2>
      <div className="project-info__tetris-images">
        <img
          className="project-info__image"
          src={Tetris1}
          alt="Tetris Screenshots"
          onClick={() => {
            window.open(Tetris1, "_blank");
          }}
        />
        <img
          className="project-info__image"
          src={Tetris2}
          alt="Tetris Screenshots"
          onClick={() => {
            window.open(Tetris2, "_blank");
          }}
        />
        <img
          className="project-info__image"
          src={Tetris3}
          alt="Tetris Screenshots"
          onClick={() => {
            window.open(Tetris3, "_blank");
          }}
        />
      </div>
      {backToProjectsButton}
    </div>
  );
}

export default Tetris;
