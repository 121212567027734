import React, { useEffect, useState } from "react";
import translations from "./translations/en.json";
import { useLocation } from "react-router-dom";

import MemoCoreValues from "../../assets/CoreValues";
import Controller from "../../assets/Controller";
import Ipad from "../../assets/Ipad";
import Arsenal from "../../assets/Arsenal";
import SocialLinkedin from "../../assets/SocialLinkedin";
import SocialGithub from "../../assets/SocialGithub";
import "./Me.scss";
import { LocationState } from "../types";
import SlideTransition from "../SlideTransition/SlideTransition";

function Me() {
  const [showMore, setShowMore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!location.state) return;

    const { resetState } = location.state as LocationState;
    if (resetState) {
      setShowMore(false);
    }
  }, [location]);

  function renderLearnMoreContent() {
    return (
      <>
        <h1 className="me-container__header">
          <span className="me-wave-emoji" role="img" aria-label="wave emoji">
            👋🏽
          </span>{" "}
          {translations.me} <br />
          {translations.intro}
          <div className="me-container__header-socials">
            <a
              className="social-link"
              href="https://www.linkedin.com/in/nabeel-chaudhry-0479b7b2/"
              target="_blank\"
              title="Link to Nabeels LinkedIn (opens in new tab)"
            >
              <SocialLinkedin className="me-social-icon" />
            </a>
            <a
              className="social-link"
              href="https://github.com/NabeelChaudhry"
              target="_blank\"
              title="Link to Nabeels GitHub (opens in new tab)"
            >
              <SocialGithub className="me-social-icon" />
            </a>
          </div>
        </h1>
        <button
          className="btn btn__me"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {translations.learnMore}
        </button>
      </>
    );
  }

  function renderMainContent() {
    return (
      <div className="me-container">
        <div className="me-content">
          <div className="core-values-content">
            <h1 className="core-values-content__header">
              <span className="pink-underline">
                {translations.coreValuesTitle}
              </span>
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: translations.coreValuesContent,
              }}
            ></p>
          </div>
          <div className="core-values-brain">
            <MemoCoreValues
              className="core-values-brain__svg"
              alt="core values"
            />
          </div>
          <div className="me-content-comp-sci">
            <h1 className="me-content-comp-sci__header">
              <span className="pink-underline">
                {translations.whyComputerScience}
              </span>
            </h1>
            <p>{translations.whyComputerScienceContent}</p>
          </div>
          <div className="me-content-hobbies">
            <h1 className="me-content-hobbies__header">
              <span className="pink-underline">{translations.hobbies}</span>
            </h1>
            <div className="hobbies-list">
              <div className="hobbies-list__games">
                <Controller className="hobbies-icon" alt="controller" />
                <div className="games-content">
                  <h2>
                    <span className="pink-underline">{translations.games}</span>
                  </h2>
                  <p>{translations.gamesContent}</p>
                </div>
              </div>
              <div className="hobbies-list__design">
                <Ipad className="hobbies-icon" alt="ipad" />
                <div className="design-content">
                  <h2>
                    <span className="pink-underline">
                      {translations.design}
                    </span>
                  </h2>
                  <p>{translations.designContent}</p>
                </div>
              </div>
              <div className="hobbies-list__arsenal">
                <Arsenal className="hobbies-icon" alt="arsenal badge" />
                <div className="design-arsenal">
                  <h2>
                    <span className="pink-underline">
                      {translations.arsenal}
                    </span>
                  </h2>
                  <p>{translations.arsenalContent}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const content = showMore ? renderMainContent() : renderLearnMoreContent();

  return <SlideTransition>{content}</SlideTransition>;
}

export default Me;
